import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IDeleteState, IResponse } from '../../types'

const sharedApiClient = AdminApiClient.getInstance()

export const fetchDeletePackage = createAsyncThunk(
  'listPackages/fetchDeletePackage',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await sharedApiClient.deletePackage(id)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IDeleteState = {
  isLoading: false,
  response: null,
  error: null
}

const deletePackageSlice = createSlice({
  name: 'packages/delete',
  initialState,
  reducers: {
    resetDeletePackage: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchDeletePackage.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchDeletePackage.fulfilled, (state, action: PayloadAction<IResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchDeletePackage.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default deletePackageSlice.reducer
export const { resetDeletePackage } = deletePackageSlice.actions
