import { useTranslation } from "react-i18next";
import { ErrorLayout } from "../layouts";
import { Button, createStyles } from "@mantine/core";
import { Dashboard as DashboardIcon } from "tabler-icons-react";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  const { t } = useTranslation();

  const useStyles = createStyles((theme) => ({
    message: {
      marginTop: theme.spacing.md,
      paddingTop: theme.spacing.md,
      paddingBottom: theme.spacing.md,
      width: '100%',
      borderTop: `1px solid ${ theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2] }`,
      textAlign: 'center',
    },
  }));
  const { classes } = useStyles();

  return (
    <ErrorLayout>
      <div className={ classes.message }>{ t('errors.content_not_found') }</div>
      <Button leftIcon={ <DashboardIcon size={ 18 }/> }
              component={ NavLink }
              to="/"
              variant="outline"
              fullWidth>{ t('go_to_dashboard') }</Button>
    </ErrorLayout>
  );
};

export default NotFound;
