import React from 'react'
import { ShowDashboard } from '../components/pages/dashboard'
import { Login } from '../components/pages/auth'
import NotFound from '../components/pages/NotFound'
import { ListUsers, CreateUser, ShowUser, EditUser } from '../components/pages/users'
import { ListLocations, CreateLocation, ShowLocation, EditLocation } from '../components/pages/locations'
import { ListEmployees, CreateEmployee, ShowEmployee, EditEmployee } from '../components/pages/employees'
import { ListVehicles, CreateVehicle, ShowVehicle, EditVehicle } from '../components/pages/vehicles'
import { ListPackages, CreatePackage, ShowPackage, EditPackage } from '../components/pages/packages'
import { ListClasses, CreateClass, ShowClass, EditClass } from '../components/pages/cls'
import { ListGroups, CreateGroup, ShowGroup, EditGroup } from '../components/pages/groups'
import { ListBrands, CreateBrand, ShowBrand, EditBrand } from '../components/pages/brands'
import { ListModels, CreateModel, ShowModel, EditModel } from '../components/pages/models'
import { ListModelDetails, CreateModelDetail, ShowModelDetail, EditModelDetail } from '../components/pages/modelDetails'
import { ListCompany, CreateCompany, ShowCompany, EditCompany } from '../components/pages/companies'
import {
  ListFinancingCompanies,
  CreateFinancingCompany,
  ShowFinancingCompany,
  EditFinancingCompany
} from '../components/pages/financingCompanies'
import {CreateInvoice, EditInvoice, ListInvoices, ShowInvoice} from "../components/pages/invoices";
import {ListROC, CreateROC, ShowROC, EditROC} from '../components/pages/revenueOutsourceCompanies'
export type RouteItemType = {
  path: string // Router path
  component?: React.ComponentType<any>
  authRequired?: boolean
  breadcrumb?: string // Translation key
  routes?: Array<RouteItemType>
}

export const routes: Array<RouteItemType> = [
  {
    path: '/',
    component: ShowDashboard,
    authRequired: true,
    breadcrumb: 'dashboard'
  },
  {
    path: '/users',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListUsers,
        authRequired: true,
        breadcrumb: 'users'
      },
      {
        path: '/create',
        component: CreateUser,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowUser,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditUser,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/packages',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListPackages,
        authRequired: true,
        breadcrumb: 'packages'
      },
      {
        path: '/create',
        component: CreatePackage,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowPackage,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditPackage,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/financing-companies',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListFinancingCompanies,
        authRequired: true,
        breadcrumb: 'financing_companies'
      },
      {
        path: '/create',
        component: CreateFinancingCompany,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowFinancingCompany,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditFinancingCompany,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/auth',
    breadcrumb: 'auth',
    authRequired: false,
    routes: [
      {
        path: '/login',
        component: Login,
        authRequired: false,
        breadcrumb: 'login'
      }
    ]
  },
  {
    path: '/vehicles',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListVehicles,
        authRequired: true,
        breadcrumb: 'vehicles'
      },
      {
        path: '/create',
        component: CreateVehicle,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowVehicle,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditVehicle,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/classes',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListClasses,
        authRequired: true,
        breadcrumb: 'classes'
      },
      {
        path: '/create',
        component: CreateClass,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowClass,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditClass,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/groups',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListGroups,
        authRequired: true,
        breadcrumb: 'groups'
      },
      {
        path: '/create',
        component: CreateGroup,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowGroup,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditGroup,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/brands',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListBrands,
        authRequired: true,
        breadcrumb: 'brands'
      },
      {
        path: '/create',
        component: CreateBrand,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowBrand,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditBrand,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/models',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListModels,
        authRequired: true,
        breadcrumb: 'models'
      },
      {
        path: '/create',
        component: CreateModel,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowModel,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditModel,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/model-details',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListModelDetails,
        authRequired: true,
        breadcrumb: 'model_details'
      },
      {
        path: '/create',
        component: CreateModelDetail,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowModelDetail,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditModelDetail,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/companies',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListCompany,
        authRequired: true,
        breadcrumb: 'companies'
      },
      {
        path: '/create',
        component: CreateCompany,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowCompany,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditCompany,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/locations',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListLocations,
        authRequired: true,
        breadcrumb: 'locations'
      },
      {
        path: '/create',
        component: CreateLocation,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowLocation,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditLocation,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/employees',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListEmployees,
        authRequired: true,
        breadcrumb: 'employees'
      },
      {
        path: '/create',
        component: CreateEmployee,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowEmployee,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditEmployee,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/invoices',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListInvoices,
        authRequired: true,
        breadcrumb: 'invoices'
      },
      {
        path: '/create',
        component: CreateInvoice,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowInvoice,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditInvoice,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '/revenue-outsource-companies',
    authRequired: true,
    routes: [
      {
        path: '/',
        component: ListROC,
        authRequired: true,
        breadcrumb: 'revenue_outsource_companies'
      },
      {
        path: '/create',
        component: CreateROC,
        authRequired: true,
        breadcrumb: 'content_actions.create'
      },
      {
        path: '/:id',
        component: ShowROC,
        authRequired: true,
        breadcrumb: 'content_actions.show'
      },
      {
        path: '/:id/edit',
        component: EditROC,
        authRequired: true,
        breadcrumb: 'content_actions.edit'
      }
    ]
  },
  {
    path: '*',
    component: NotFound,
    breadcrumb: 'login'
  }
]

export default routes
