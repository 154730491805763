import {AxiosError} from "axios";
import AppStorage from "./storage";
import i18n from "./i18n";
import {FormErrorType} from "../types";

/**
 * Change locale of the application.
 * @param locale
 */
export const changeLocale = async (locale: string) => {
  await i18n.changeLanguage(locale);
  AppStorage.setLocale(locale);
};

/**
 * New line to br.
 * @param content
 */
export const nl2br = (content: string): string => {
  return content.replace(/\r\n|\r|\n/g, '<br>');
};

/**
 * File object to base64 string.
 * @param file
 * @param onlyContent
 */
export const fileObjectToBase64 = async (file: File, onlyContent: boolean = true): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    if (file instanceof File) {
      reader.onloadend = (event: any) => {
        const result = onlyContent ? String(event.target.result).split(',')[1] : event.target.result;

        return resolve(result);
      };

      reader.onerror = (error) => {
        return reject(error);
      };

      reader.readAsDataURL(file);
    } else {
      return reject('Incorrect file object!');
    }
  })
}

/**
 * Get formatted validation errors.
 * @param error
 */
export const getFormattedValidationErrors = (error: AxiosError | any) => {
  return Object.keys(error.response.data.errors).map((key: string) => ({
    key,
    message: error.response.data.errors[key].join('\n'),
  } as FormErrorType));
};

/**
 * Get enum value by key.
 * @param enumObj
 * @param key
 */
export const getEnumValueByKey = (enumObj: any, key: any) => {
  try {
    return enumObj[key];
  } catch (e) {
    return null;
  }
}
