import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import qs from "query-string";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MediaQuery,
  Pagination,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import {
  Search as SearchIcon,
  SquarePlus as SquarePlusIcon,
} from "tabler-icons-react";
import {MainLayout} from "../../layouts";
import {useAppDispatch, useAppSelector} from "../../../store";
import {ICompaniesQueryParams} from "../../../store/types";
import {DEFAULT_PER_PAGE, SELECT_PER_PAGE_OPTIONS} from "../../../types";
import {fetchListROCS} from "../../../store/features/revenueOutsourceCompanies/listROCSlice";
import {
  fetchDeleteROC,
  resetDeleteROC,
} from "../../../store/features/revenueOutsourceCompanies/deleteROCSlice";
import {ContentBox, ContentNotFound, ItemActionsMenu} from "../../partials";

const List = () => {
  const {t} = useTranslation();
  const {search} = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useState<ICompaniesQueryParams>({
    page: 1,
    per: DEFAULT_PER_PAGE,
    ...qs.parse(search),
  });

  const {isLoading, response, error} = useAppSelector(
    (state) => state.listROC
  );
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError,
  } = useAppSelector((state) => state.deleteROC);

  const filterForm = useForm({
    initialValues: {
      q: queryParams.q || "",
      per: queryParams.per,
    },
  });

  const handleFilterSubmit = async (data: ICompaniesQueryParams) => {
    setQueryParams({...queryParams, ...data, page: 1});
    navigate(`/revenue-outsource-companies?${qs.stringify(data)}`, {
      replace: true,
    });
  };

  useEffect(() => {
    dispatch(fetchListROCS({query: queryParams}));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (error !== null && error.response.status === 401) {
      dispatch(fetchListROCS({query: queryParams}));
    }
  }, [error, dispatch, queryParams]);

  const handleChangePage = (page: number) => {
    if (page !== Number(queryParams.page)) {
      setQueryParams({...queryParams, page});
      navigate(
        `/revenue-outsource-companies?${qs.stringify({
          ...queryParams,
          page,
        })}`,
        {replace: true}
      );
    }
  };

  const handleDelete = async (id: string) => {
    dispatch(fetchDeleteROC(id));
  };

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetDeleteROC());

      showNotification({
        color: "green",
        title: t("successful"),
        message: t("deleted"),
      });

      setQueryParams({...queryParams, page: 1});
      navigate(`/revenue-outsource-companies?${qs.stringify({...queryParams, page: 1})}`, {
        replace: true,
      });
    }
  }, [deleteResponse, queryParams, t, dispatch, navigate]);

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetDeleteROC());

      showNotification({
        color: "red",
        title: t("successful"),
        message: deleteError.response
          ? deleteError.response.message
          : t("errors.unknown"),
      });
    }
  }, [deleteError, t, dispatch]);

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading}/>
      {response && (
        <MediaQuery largerThan="md" styles={{flexDirection: "row-reverse"}}>
          <Grid gutter="sm">
            <Grid.Col lg={3} md={4} sm={12}>
              <ContentBox title={t("filter")}>
                <form onSubmit={filterForm.onSubmit(handleFilterSubmit)}>
                  <TextInput
                    placeholder={t("keywords")}
                    label={t("keywords")}
                    mb="xs"
                    {...filterForm.getInputProps("q")}
                  />
                  <Select
                    data={SELECT_PER_PAGE_OPTIONS}
                    label={t("per_page")}
                    placeholder={t("select")}
                    mb="xs"
                    {...filterForm.getInputProps("per")}
                  />
                  <Button
                    type="submit"
                    leftIcon={<SearchIcon size={16}/>}
                    variant="outline"
                    color="cyan"
                    fullWidth
                  >
                    {t("filter")}
                  </Button>
                </form>
              </ContentBox>
            </Grid.Col>
            <Grid.Col lg={9} md={8} sm={12}>
              <ContentBox
                title={t("revenue_outsource_companies")}
                subtitle={`${t("total")}: ${response.data.count}`}
                withoutSpacing
              >
                {response.data.items.length > 0 ? (
                  <div className="table-responsive">
                    <Table striped highlightOnHover>
                      <thead>
                      <tr>
                        <th>{t("name")}</th>
                        <th>{t("companies")}</th>
                        <th>{t("status")}</th>
                        <th className="text-center">{t("actions")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {response?.data?.items.map((roc: any) => (
                        <tr key={roc.id}>
                          <td>{roc?.name}</td>
                          <td>
                            {roc?.companies.map(
                              (item: any, index: number) => (
                                <Badge key={item.id} mr="xs">{item.name}</Badge>
                              )
                            )}
                          </td>
                          <td>{t(`statuses.${roc.status}`)}</td>
                          <td className="text-center">
                            <ItemActionsMenu
                              show={`/revenue-outsource-companies/${roc.id}`}
                              edit={`/revenue-outsource-companies/${roc.id}/edit`}
                              onDelete={() => handleDelete(roc.id)}
                            />
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <ContentNotFound/>
                )}
              </ContentBox>
              <ContentBox>
                <Grid>
                  <Grid.Col span={8}>
                    {response.data.pagination.last > 1 && (
                      <Pagination
                        page={Number(queryParams.page)}
                        onChange={handleChangePage}
                        total={response.data.pagination.last}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Group position="right">
                      <Button
                        leftIcon={<SquarePlusIcon size={16}/>}
                        component={Link}
                        to="/revenue-outsource-companies/create"
                        variant="outline"
                        color="green"
                      >
                        {t("content_actions.create")}
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </ContentBox>
            </Grid.Col>
          </Grid>
        </MediaQuery>
      )}
    </MainLayout>
  );
};

export default List;
