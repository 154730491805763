import { useTranslation } from "react-i18next";
import { Button } from "@mantine/core";
import { DeviceFloppy as DeviceFloppyIcon } from "tabler-icons-react";

type PropsType = {
  form: string;
}

const SaveButton = ({ form }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Button type="submit"
            leftIcon={ <DeviceFloppyIcon size={ 18 }/> }
            form={ form }>{ t('content_actions.save') }</Button>
  );
}

export default SaveButton;
