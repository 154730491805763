import React, {useEffect, useState} from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {
  ActionIcon,
  Anchor,
  Badge, Button,
  Center,
  Container,
  Divider,
  Grid,
  Group,
  LoadingOverlay, Modal, ScrollArea,
  Table,
  Text
} from '@mantine/core'
import {showNotification} from '@mantine/notifications'
import {MainLayout} from '../../layouts'
import {useAppDispatch, useAppSelector} from '../../../store'
import {InvoiceType, TransactionType} from '../../../store/types'
import {BackListButton, ContentBox, ContentNotFound, InvoiceItemActionsMenu} from '../../partials'
import {fetchShowInvoice, resetShowInvoice} from '../../../store/features/invoices/showInvoiceSlice'
import {fetchDeleteInvoice, resetDeleteInvoice} from '../../../store/features/invoices/deleteInvoiceSlice'
import {
  fetchStopContinuallyInvoice,
  resetStopContinuallyInvoice
} from '../../../store/features/invoices/stopContinuallyInvoiceSlice'
import Moment from "react-moment";
import {DATE_FORMAT, DATETIME_FORMAT, TransactionStatusColorType} from "../../../types";
import {getEnumValueByKey} from "../../../utils/mixins";
import {Eye as EyeIcon} from 'tabler-icons-react'
import {Prism} from "@mantine/prism";
import {useModals} from "@mantine/modals";
import {fetchResendInvoice, resetResendInvoice} from "../../../store/features/invoices/resendInvoiceSlice";

const Show = () => {
  const {t} = useTranslation()
  const {id} = useParams<{ id: string }>()
  const modals = useModals()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [data, setData] = useState<InvoiceType | null>(null)
  const [openedTransaction, setOpenedTransaction] = useState<TransactionType | null>(null)
  const {isLoading, response, error} = useAppSelector(state => state.showInvoice)
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError
  } = useAppSelector(state => state.deleteInvoice)
  const {
    isLoading: stopContinuallyIsLoading,
    response: stopContinuallyResponse,
    error: stopContinuallyError
  } = useAppSelector(state => state.stopContinually)
  const {
    isLoading: resendIsLoading,
    response: resendResponse,
    error: resendError
  } = useAppSelector(state => state.resendInvoice)

  useEffect(() => {
    if (response === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowInvoice(id as string))
      })()
    }
  }, [response, data, id, dispatch])

  useEffect(() => {
    if (response !== null) {
      setData(response.data)

      if (data !== null) {
        dispatch(resetShowInvoice())
      }
    }
  }, [response, dispatch, data])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowInvoice())
          await dispatch(fetchShowInvoice(id))
        })()
      } else if (error.response.status === 404) {
        dispatch(resetShowInvoice())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(error.data.message)
        })

        navigate('/invoices')
      } else {
        dispatch(resetShowInvoice())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/invoices')
      }
    }
  }, [error, id, dispatch, t, navigate])

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetShowInvoice())
      dispatch(resetDeleteInvoice())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted')
      })

      navigate('/invoices')
    }
  }, [deleteResponse, t, dispatch, navigate])

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetShowInvoice())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: deleteError.response.data ? deleteError.response.data.message : t('errors.unknown')
      })
    }
  }, [deleteError, t, dispatch])

  useEffect(() => {
    if (stopContinuallyResponse !== null) {
      dispatch(resetShowInvoice())
      dispatch(fetchShowInvoice(id as string))
      dispatch(resetStopContinuallyInvoice())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('stopped')
      })
    }
  }, [stopContinuallyResponse, t, dispatch, id])

  useEffect(() => {
    if (stopContinuallyError !== null) {
      dispatch(resetStopContinuallyInvoice())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: stopContinuallyError.response.data ? stopContinuallyError.response.data.message :
          t('errors.unknown')
      })
    }
  }, [stopContinuallyError, t, dispatch])

  getEnumValueByKey(TransactionStatusColorType, 'waiting')

  const handleDelete = async (id: string) => {
    await dispatch(fetchDeleteInvoice(id))
  }

  const handleStopContinually = async (id: string) => {
    await dispatch(fetchStopContinuallyInvoice(id))
  }

  const stopContinuallyConfirmModal = (id: string) => {
    modals.openConfirmModal({
      title: t('confirmation_required'),
      centered: true,
      children: <Text size="sm">{t('stop_continually_message')}</Text>,
      labels: {confirm: t('yes'), cancel: t('no')},
      confirmProps: {color: 'red'},
      onCancel: () => {
      },
      onConfirm: () => handleStopContinually(id)
    })
  }

  const handleResend = async (id: string) => {
    await dispatch(fetchResendInvoice(id))
  }

  useEffect(() => {
    if (resendResponse !== null) {
      dispatch(resetResendInvoice())
      dispatch(fetchShowInvoice(id as string))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('email_resent')
      })
    }
  }, [resendResponse, t, dispatch, id])

  useEffect(() => {
    if (resendError !== null) {
      dispatch(resetDeleteInvoice())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: resendError.response ? resendError.response.data.message : t('errors.unknown')
      })
    }
  }, [resendError, t, dispatch])

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || resendIsLoading || deleteIsLoading || stopContinuallyIsLoading}/>
      {data && (
        <>
          <ContentBox title={t('content_actions.show')}>
            <h4>{t('id')}:</h4>
            <Text>{data.id}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('company')}:</h4>
            <Anchor
              component={NavLink}
              to={`/companies/${data.company.id}`}
            >
              <span>{data.company.name}</span>
            </Anchor>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('months')}:</h4>
            <Text>{data.months}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('amount')}:</h4>
            <Text>${data.amount}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('next_invoice_date')}:</h4>
            <Text>
              {data.next_date ? (
                <>
                  <Moment format={DATE_FORMAT}>{data.next_date}</Moment>
                  {data.next_date && (
                    <Button size="sm" color="red" ml="sm" onClick={() => stopContinuallyConfirmModal(data.id)}>
                      {t('content_actions.stop_continually')}
                    </Button>
                  )}
                </>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('due_date')}:</h4>
            <Text>
              {data.due_date ? (
                <Moment format={DATE_FORMAT}>{data.due_date}</Moment>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('description')}:</h4>
            <Text dangerouslySetInnerHTML={{ __html: data.description ? data.description : '-' }}/>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('paid_date')}:</h4>
            <Text>
              {data.paid_at ? (
                <Moment format={DATETIME_FORMAT}>{data.paid_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('transactions')}:</h4>
            <Text>
              {data.transactions?.length ? (
                <div className="table-responsive">
                  <Table striped highlightOnHover>
                    <thead>
                    <tr>
                      <th>{t('user')}</th>
                      <th>{t('provider')}</th>
                      <th className="text-center">{t('payment_status')}</th>
                      <th>{t('created_at')}</th>
                      <th>{t('completed_at')}</th>
                      <th className="text-center">{t('details')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.transactions.map((transaction, key) => (
                      <tr key={key}>
                        <td>
                          <Anchor
                            component={NavLink}
                            to={`/users/${transaction.user.id}`}
                          >
                            <span>{transaction.user.last_name}, {transaction.user.name} - {transaction.user.email}</span>
                          </Anchor>
                        </td>
                        <td>{t(`payment_providers.${transaction.provider}`)}</td>
                        <td className="text-center">
                          <Badge
                            color={getEnumValueByKey(TransactionStatusColorType, transaction.status.toUpperCase()) ? getEnumValueByKey(TransactionStatusColorType, transaction.status.toUpperCase())?.toString() : 'gray'}>
                            {t(`transaction_statuses.${transaction.status}`)}
                          </Badge>
                        </td>
                        <td>
                          <Text>
                            {transaction?.created_at ? (
                              <Moment format={DATETIME_FORMAT}>{transaction.created_at}</Moment>
                            ) : (
                              <>-</>
                            )}
                          </Text>
                        </td>
                        <td>
                          <Text>
                            {transaction?.completed_at ? (
                              <Moment format={DATETIME_FORMAT}>{transaction.completed_at}</Moment>
                            ) : (
                              <>-</>
                            )}
                          </Text>
                        </td>
                        <td className="text-center">
                          <Center>
                            <ActionIcon
                              variant="outline"
                              color="blue"
                              size="sm"
                              onClick={() => setOpenedTransaction(transaction)}
                            >
                              <EyeIcon size={16}/>
                            </ActionIcon>
                          </Center>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <ContentNotFound/>
              )}
            </Text>

            <h4>{t('email_logs')}:</h4>
            <Text>
              {data.email_logs?.length ? (
                <div className="table-responsive">
                  <Table striped highlightOnHover>
                    <tbody>
                    {data.email_logs.map((email_log, key) => (
                      <tr key={key}>
                        <td>
                          <Text>
                            {email_log?.created_at ? (
                              <Moment format={DATETIME_FORMAT}>{email_log.created_at}</Moment>
                            ) : (
                              <>-</>
                            )}
                          </Text>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <ContentNotFound/>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('created_at')}:</h4>
            <Text>
              {data.created_at ? (
                <Moment format={DATETIME_FORMAT}>{data.created_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('updated_at')}:</h4>
            <Text>
              {data.updated_at ? (
                <Moment format={DATETIME_FORMAT}>{data.updated_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>
          </ContentBox>
          <ContentBox>
            <Grid>
              <Grid.Col span={6}>
                <BackListButton to="/invoices"/>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group
                  position="right"
                  pr="md"
                >
                  <InvoiceItemActionsMenu
                    edit={`/invoices/${data.id}/edit`}
                    resend={() => handleResend(data.id)}
                    onDelete={() => handleDelete(data.id)}
                  />
                </Group>
              </Grid.Col>
            </Grid>
          </ContentBox>
          <Modal
            opened={openedTransaction !== null}
            onClose={() => setOpenedTransaction(null)}
            title={t('transaction_details')}
            centered
            size="xl"
            overflow="inside"
          >
            {openedTransaction && (
              <div className="table-responsive">
                <Table striped>
                  <tbody>
                  <tr>
                    <td>
                      <strong>{t('id')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{openedTransaction.id}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('user')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Anchor
                        component={NavLink}
                        to={`/users/${openedTransaction.user.id}`}
                      >
                        <span>{openedTransaction.user.last_name}, {openedTransaction.user.name} - {openedTransaction.user.email}</span>
                      </Anchor>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('provider')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{t(`payment_providers.${openedTransaction.provider}`)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('session_id')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{openedTransaction.session_id}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('amount')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>${openedTransaction.amount}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('payment_status')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Badge
                        color={getEnumValueByKey(TransactionStatusColorType, openedTransaction.status.toUpperCase()) ?
                          getEnumValueByKey(TransactionStatusColorType, openedTransaction.status.toUpperCase())?.toString() : 'gray'}>
                        {t(`transaction_statuses.${openedTransaction.status}`)}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('created_at')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {openedTransaction?.created_at ? (
                        <Moment format={DATETIME_FORMAT}>{openedTransaction.created_at}</Moment>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('updated_at')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {openedTransaction?.updated_at ? (
                        <Moment format={DATETIME_FORMAT}>{openedTransaction.updated_at}</Moment>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('completed_at')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {openedTransaction?.completed_at ? (
                        <Moment format={DATETIME_FORMAT}>{openedTransaction.completed_at}</Moment>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('refund_at')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {openedTransaction?.refund_at ? (
                        <Moment format={DATETIME_FORMAT}>{openedTransaction.refund_at}</Moment>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('data')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {openedTransaction?.data ? (
                        <Container size="sm" style={{padding: 0}}>
                          <ScrollArea offsetScrollbars={false} style={{height: 250}}>
                            <Prism
                              withLineNumbers
                              language="json"
                            >{JSON.stringify(openedTransaction.data, null, 2)}</Prism>
                          </ScrollArea>
                        </Container>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('results')}:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {openedTransaction?.results ? (
                        <Container size="sm" style={{padding: 0}}>
                          <ScrollArea style={{height: 250}}>
                            <Prism
                              withLineNumbers
                              language="json"
                            >{JSON.stringify(openedTransaction.results, null, 2)}</Prism>
                          </ScrollArea>
                        </Container>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </Modal>
        </>
      )}
    </MainLayout>
  )
}

export default Show
