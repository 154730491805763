import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import qs from 'query-string'
import {useForm} from '@mantine/form'
import {showNotification} from '@mantine/notifications'
import {
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MediaQuery,
  Pagination,
  Select,
  SelectItem,
  Table,
  TextInput
} from '@mantine/core'
import {Search as SearchIcon, SquarePlus as SquarePlusIcon} from 'tabler-icons-react'
import {MainLayout} from '../../layouts'
import {useAppDispatch, useAppSelector} from '../../../store'
import {IInvoicesQueryParams} from '../../../store/types'
import {DATE_FORMAT, DATETIME_FORMAT, DEFAULT_PER_PAGE, SELECT_PER_PAGE_OPTIONS} from '../../../types'
import {fetchListInvoices} from '../../../store/features/invoices/listInvoicesSlice'
import {ContentBox, ContentNotFound, InvoiceItemActionsMenu} from '../../partials'
import {fetchDeleteInvoice, resetDeleteInvoice} from "../../../store/features/invoices/deleteInvoiceSlice";
import Moment from "react-moment";
import {fetchListCompanies} from "../../../store/features/companies/listCompanySlice";
import {fetchResendInvoice, resetResendInvoice} from "../../../store/features/invoices/resendInvoiceSlice";

const List = () => {
  const {t} = useTranslation()
  const {search} = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [queryParams, setQueryParams] = useState<IInvoicesQueryParams>({
    page: 1,
    per: DEFAULT_PER_PAGE,
    ...qs.parse(search)
  })
  const {isLoading: companyIsLoading, response: companyResponse} = useAppSelector(state => state.listCompany)
  const [companiesOptions, setCompaniesOptions] = useState<SelectItem[]>([])

  const {isLoading, response, error} = useAppSelector(state => state.listInvoices)
  const {
    isLoading: resendIsLoading,
    response: resendResponse,
    error: resendError
  } = useAppSelector(state => state.resendInvoice)
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError
  } = useAppSelector(state => state.deleteInvoice)

  const filterForm = useForm({
    initialValues: {
      q: queryParams.q || '',
      company: queryParams.company,
      is_paid: queryParams.is_paid,
      per: queryParams.per
    }
  })

  const handleFilterSubmit = async (data: IInvoicesQueryParams) => {
    setQueryParams({...queryParams, ...data, page: 1})
    navigate(`/invoices?${qs.stringify(data)}`, {replace: true})
  }

  useEffect(() => {
    dispatch(fetchListInvoices({query: queryParams}))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (error !== null && error.response.status === 401) {
      dispatch(fetchListInvoices({query: queryParams}))
    }
  }, [error, dispatch, queryParams])

  useEffect(() => {
    if (companyResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListCompanies({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setCompaniesOptions(
        companyResponse.data.items.map((comapny: any) => ({
          value: comapny.id,
          label: `${comapny.name}`
        }))
      )
    }
  }, [companyResponse, dispatch])

  const handleChangePage = (page: number) => {
    if (page !== Number(queryParams.page)) {
      setQueryParams({...queryParams, page})
      navigate(`/invoices?${qs.stringify({...queryParams, page})}`, {replace: true})
    }
  }

  const handleDelete = async (id: string) => {
    await dispatch(fetchDeleteInvoice(id))
  }

  const handleResend = async (id: string) => {
    await dispatch(fetchResendInvoice(id))
  }

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetDeleteInvoice())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted')
      })

      setQueryParams({...queryParams, page: 1})
      navigate(`/invoices?${qs.stringify({...queryParams, page: 1})}`, {replace: true})
    }
  }, [deleteResponse, queryParams, t, dispatch, navigate])

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetDeleteInvoice())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: deleteError.response ? deleteError.response.data.message : t('errors.unknown')
      })
    }
  }, [deleteError, t, dispatch])

  useEffect(() => {
    if (resendResponse !== null) {
      dispatch(resetResendInvoice())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('email_resent')
      })
    }
  }, [resendResponse, t, dispatch])

  useEffect(() => {
    if (resendError !== null) {
      dispatch(resetDeleteInvoice())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: resendError.response ? resendError.response.data.message : t('errors.unknown')
      })
    }
  }, [resendError, t, dispatch])

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || resendIsLoading || deleteIsLoading}/>
      {response && (
        <MediaQuery
          largerThan="md"
          styles={{flexDirection: 'row-reverse'}}
        >
          <Grid gutter="sm">
            <Grid.Col
              lg={3}
              md={4}
              sm={12}
            >
              <ContentBox title={t('filter')}>
                <form onSubmit={filterForm.onSubmit(handleFilterSubmit)}>
                  <Select
                    label={t('company')}
                    placeholder={t('company')}
                    data={companiesOptions}
                    disabled={companyIsLoading}
                    searchable
                    nothingFound={t('errors.no_options')}
                    clearable
                    mb="xs"
                    {...filterForm.getInputProps('company')}
                  />
                  <Select
                    data={[
                      {value: '', label: t('all')},
                      {value: 'true', label: t('paid')},
                      {value: 'false', label: t('unpaid')}
                    ]}
                    label={t('payment_status')}
                    placeholder={t('select')}
                    mb="xs"
                    {...filterForm.getInputProps('is_paid')}
                  />
                  <TextInput
                    placeholder={t('keywords')}
                    label={t('keywords')}
                    mb="xs"
                    {...filterForm.getInputProps('q')}
                  />
                  <Select
                    data={SELECT_PER_PAGE_OPTIONS}
                    label={t('per_page')}
                    placeholder={t('select')}
                    mb="xs"
                    {...filterForm.getInputProps('per')}
                  />
                  <Button
                    type="submit"
                    leftIcon={<SearchIcon size={16}/>}
                    variant="outline"
                    color="cyan"
                    fullWidth
                  >
                    {t('filter')}
                  </Button>
                </form>
              </ContentBox>
            </Grid.Col>
            <Grid.Col
              lg={9}
              md={8}
              sm={12}
            >
              <ContentBox
                title={t('invoices')}
                subtitle={`${t('total')}: ${response.data.count}`}
                withoutSpacing
              >
                {response.data.items.length > 0 ? (
                  <div className="table-responsive">
                    <Table
                      striped
                      highlightOnHover
                    >
                      <thead>
                      <tr>
                        <th>{t('company')}</th>
                        <th className="text-center">{t('months')}</th>
                        <th className="text-right">{t('amount')}</th>
                        <th className="text-center">{t('next_invoice_date')}</th>
                        <th className="text-center">{t('payment_status')}</th>
                        <th className="text-center">{t('created_at')}</th>
                        <th className="text-center">{t('actions')}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {response.data.items.map(({id, company, months, amount, paid_at, next_date, created_at}) => (
                        <tr key={id}>
                          <td>{company.name}</td>
                          <td className="text-center">{months}</td>
                          <td className="text-right">${amount}</td>
                          <td className="text-center">
                            {next_date ? (
                              <Moment format={DATE_FORMAT}>{next_date}</Moment>
                            ) : (
                              <>-</>
                            )}
                          </td>
                          <td className="text-center">
                            {paid_at ? (
                              <Badge color="green">
                                <Moment format={DATETIME_FORMAT}>{paid_at}</Moment>
                              </Badge>
                            ) : (
                              <Badge color="red">{t('unpaid')}</Badge>
                            )}
                          </td>
                          <td className="text-center">
                            <Moment format={DATE_FORMAT}>{created_at}</Moment>
                          </td>
                          <td className="text-center">
                            <InvoiceItemActionsMenu
                              show={`/invoices/${id}`}
                              edit={`/invoices/${id}/edit`}
                              resend={() => handleResend(id)}
                              onDelete={() => handleDelete(id)}
                            />
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <ContentNotFound/>
                )}
              </ContentBox>
              <ContentBox>
                <Grid>
                  <Grid.Col span={8}>
                    {response.data.pagination.last > 1 && (
                      <Pagination
                        page={Number(queryParams.page)}
                        onChange={handleChangePage}
                        total={response.data.pagination.last}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Group position="right">
                      <Button
                        leftIcon={<SquarePlusIcon size={16}/>}
                        component={Link}
                        to="/invoices/create"
                        variant="outline"
                        color="green"
                      >
                        {t('content_actions.create')}
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </ContentBox>
            </Grid.Col>
          </Grid>
        </MediaQuery>
      )}
    </MainLayout>
  )
}

export default List
