import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IModelDetailsParams, IModelDetailsResponse, IModelDetailsState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListModelDetails = createAsyncThunk(
  'listModelDetails/fetchListModelDetails',
  async ({ query }: IModelDetailsParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getModelDetails(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IModelDetailsState = {
  isLoading: false,
  response: null,
  error: null
}

const listModelDetailsSlice = createSlice({
  name: 'modeldetails/list',
  initialState,
  reducers: {
    resetListModelDetails: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListModelDetails.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListModelDetails.fulfilled, (state, action: PayloadAction<IModelDetailsResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListModelDetails.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listModelDetailsSlice.reducer
export const { resetListModelDetails } = listModelDetailsSlice.actions
