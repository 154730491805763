import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IReportLogsParams, IReportLogsResponse, IReportLogsState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListReportLogs = createAsyncThunk(
  'listReportLogs/fetchListReportLogs',
  async ({ id, query }: IReportLogsParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.showReportLogsUser(id, query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IReportLogsState = {
  isLoading: false,
  response: null,
  error: null
}

const listReportLogsSlice = createSlice({
  name: 'reportLogs/list',
  initialState,
  reducers: {
    resetListReportLogs: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListReportLogs.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListReportLogs.fulfilled, (state, action: PayloadAction<IReportLogsResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListReportLogs.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listReportLogsSlice.reducer
export const { resetListReportLogs } = listReportLogsSlice.actions
