import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Anchor, Breadcrumbs, createStyles } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import { getFlatRoutes } from '../../router'
import { routes } from '../../config'

const Breadcrumb = () => {
  const { t, i18n } = useTranslation()
  const routeItems = useReactRouterBreadcrumbs(getFlatRoutes(routes))
  const [items, setItems] = useState<Array<React.ReactElement>>([])

  const useStyles = createStyles(theme => ({
    container: {
      padding: theme.spacing.sm,
      marginBottom: theme.spacing.sm,
      textTransform: 'capitalize',
      height: 42,
      background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
      border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]}`
    }
  }))
  const { classes } = useStyles()

  useEffect(() => {
    if (routes.length > 0 && items.length === 0) {
      const newItems: Array<React.ReactElement> = []

      routeItems.forEach(({ match }, key) => {
        if (match.route?.breadcrumb && match.route.path) {
          newItems.push(
            <Anchor
              component={NavLink}
              to={match.pathname}
              key={key}
              color="gray"
            >
              <span>{t(match.route.breadcrumb.toString())}</span>
            </Anchor>
          )
        }
      })

      setItems(newItems)
    }
  }, [routeItems, items, t])

  useEffect(() => {
    setItems([])
  }, [i18n.language])

  return <Breadcrumbs className={classes.container}>{items}</Breadcrumbs>
}

export default Breadcrumb
