import React from "react";
import { Container, createStyles, Grid } from "@mantine/core";

interface IContentBoxProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  withoutSpacing?: boolean;
}

const ContentBox = ({ title, subtitle, children, withoutSpacing }: IContentBoxProps) => {
  const useStyles = createStyles((theme) => ({
    container: {
      padding: 0,
      background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : '#fff',
      border: `1px solid ${ theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2] }`,
    },
    head: {
      padding: theme.spacing.sm,
      borderBottom: `1px solid ${ theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2] }`,
    },
    title: {
      fontWeight: 'bold',
      fontSize: theme.fontSizes.sm,
    },
    subtitle: {
      textAlign: 'right',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    },
    body: {
      padding: withoutSpacing ? 0 : theme.spacing.sm,
    },
  }));
  const { classes } = useStyles();

  return (
    <Container fluid className={ classes.container }>
      { (title || subtitle) &&
        <div className={ classes.head }>
          <Grid>
            { title &&
              <Grid.Col span={ 8 } className={ classes.title }>{ title }</Grid.Col>
            }
            { subtitle &&
              <Grid.Col span={ 4 } className={ classes.subtitle }>{ subtitle }</Grid.Col>
            }
          </Grid>
        </div>
      }
      <div className={ classes.body }>{ children }</div>
    </Container>
  );
};

export default ContentBox;
