import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IFinancingCompanyParams, IFinancingCompanyResponse, IFinancingCompanyState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchCreateFinancingCompany = createAsyncThunk(
  'listFinancingCompanies/fetchCreateFinancingCompany',
  async (params: IFinancingCompanyParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.createFinancingCompany(params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IFinancingCompanyState = {
  isLoading: false,
  response: null,
  error: null
}

const createFinancingCompanySlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetCreateFinancingCompany: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCreateFinancingCompany.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(
      fetchCreateFinancingCompany.fulfilled,
      (state, action: PayloadAction<IFinancingCompanyResponse>) => {
        state.isLoading = false
        state.response = action.payload
        state.error = null
      }
    )

    builder.addCase(fetchCreateFinancingCompany.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default createFinancingCompanySlice.reducer
export const { resetCreateFinancingCompany } = createFinancingCompanySlice.actions
