import {AxiosRequestConfig, AxiosResponse} from 'axios'
import AbstractHttpClient from './AbstractHttpClient'
import {app} from '../../config'
import {
  IUserParams,
  IUsersQueryParams,
  ILocationParams,
  ILocationsQueryParams,
  IEmployeeParams,
  IEmployeesQueryParams,
  IPackagesQueryParams,
  IPackageParams,
  IFinancingCompaniesQueryParams,
  IFinancingCompanyParams,
  IClassesQueryParams,
  IClassParams,
  IGroupsQueryParams,
  IGroupParams,
  IBrandsQueryParams,
  IBrandParams,
  IModelsQueryParams,
  IModelParams,
  IModelDetailsQueryParams,
  IModelDetailParams,
  ICompaniesQueryParams,
  ICompanyParams,
  IVehiclesQueryParams,
  IVehicleParams,
  IInvoicesQueryParams,
  IInvoiceParams,
  IReportLogsQueryParams,
  ICompanyVehicleGroupsQueryParams,
  ICompanyVehicleClassesQueryParams
} from '../../store/types'

export default class AdminApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: AdminApiClient

  /**
   * @private constructor
   */
  private constructor() {
    super(app.API_URL.replace(/^\/|\/$/g, '').concat('/admin'))

    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }

  /**
   * @private _initializeRequestInterceptor
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._setAccessToken)
  }

  /**
   * @param config
   */
  private _setAccessToken = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers = config.headers ?? {}
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._authenticatedHandleError)
  }

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => {
    return response
  }

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this()
    }

    return this.classInstance
  }

  /**
   * Dashboard.
   */
  public dashboard = async () => await this.instance.get('/dashboard')

  /**
   * Users.
   */
  public users = async (queryParams?: IUsersQueryParams) =>
    await this.instance.get('/users', {
      params: queryParams
    })

  /**
   * User.
   */
  public user = async (id: string) => await this.instance.get(`/user/${id}`)

  /**
   * Create user.
   */
  public createUser = async (params: IUserParams) => await this.instance.put('/users', params)

  /**
   * Update user.
   */
  public updateUser = async (id: string, params: IUserParams) => {
    return await this.instance.patch(`/user/${id}`, params)
  }

  /**
   * Delete user.
   */
  public deleteUser = async (id: string) => await this.instance.delete(`/user/${id}`)

  /**
   * Impersonate user.
   * @param id
   */
  public impersonateUser = async (id: string) => await this.instance.get(`/user/${id}/token`)

  /**
   * Send reports user.
   * @param id
   */
  public sendReportsUser = async (id: string) => await this.instance.get(`/user/${id}/send-reports`)

  /**
   * Show report logs for user.
   * @param id
   * @param queryParams
   */
  public showReportLogsUser = async (id: string, queryParams?: IReportLogsQueryParams) =>
    await this.instance.get(`/user/${id}/report-logs`, {
      params: queryParams
    })

  /**
   * Packages.
   */
  public packages = async (queryParams?: IPackagesQueryParams) =>
    await this.instance.get('/packages', {
      params: queryParams
    })

  /**
   * Package.
   */
  public package = async (id: string) => await this.instance.get(`/package/${id}`)

  /**
   * Create package.
   */
  public createPackage = async (params: IPackageParams) => await this.instance.put('/packages', params)

  /**
   * Update package.
   */
  public updatePackage = async (id: string, params: IPackageParams) => {
    return await this.instance.patch(`/package/${id}`, params)
  }

  /**
   * Delete package.
   */
  public deletePackage = async (id: string) => await this.instance.delete(`/package/${id}`)

  /**
   * Locations.
   */
  public locations = async (queryParams?: ILocationsQueryParams) =>
    await this.instance.get('/locations', {
      params: queryParams
    })

  /**
   * Package.
   */
  public location = async (id: string) => await this.instance.get(`/location/${id}`)

  /**
   * Create package.
   */
  public createLocation = async (params: ILocationParams) => await this.instance.put('/locations', params)

  /**
   * Update package.
   */
  public updateLocation = async (id: string, params: ILocationParams) => {
    return await this.instance.patch(`/location/${id}`, params)
  }

  /**
   * Delete package.
   */
  public deleteLocation = async (id: string) => await this.instance.delete(`/location/${id}`)

  /**
   * Employees.
   */
  public employees = async (queryParams?: IEmployeesQueryParams) =>
    await this.instance.get('/employees', {
      params: queryParams
    })

  /**
   * Package.
   */
  public employee = async (id: string) => await this.instance.get(`/employee/${id}`)

  /**
   * Create package.
   */
  public createEmployee = async (params: IEmployeeParams) => await this.instance.put('/employees', params)

  /**
   * Update package.
   */
  public updateEmployee = async (id: string, params: IEmployeeParams) => {
    return await this.instance.patch(`/employee/${id}`, params)
  }

  /**
   * Delete package.
   */
  public deleteEmployee = async (id: string) => await this.instance.delete(`/employee/${id}`)

  /**
   * Financing companies.
   */
  public financingCompanies = async (queryParams?: IFinancingCompaniesQueryParams) =>
    await this.instance.get('/financing-companies', {
      params: queryParams
    })

  /**
   * Financing company.
   */
  public financingCompany = async (id: string) => await this.instance.get(`/financing-company/${id}`)

  /**
   * Create company.
   */
  public createFinancingCompany = async (params: IFinancingCompanyParams) =>
    await this.instance.put('/financing-companies', params)

  /**
   * Update company.
   */
  public updateFinancingCompany = async (id: string, params: IFinancingCompanyParams) => {
    return await this.instance.patch(`/financing-company/${id}`, params)
  }

  /**
   * Delete company.
   */
  public deleteFinancingCompany = async (id: string) => await this.instance.delete(`/financing-company/${id}`)

  /**
   * Classes.
   */
  public getClasses = async (queryParams?: IClassesQueryParams) =>
    await this.instance.get('/classes', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getClass = async (id: string) => await this.instance.get(`/class/${id}`)

  /**
   * Create class.
   */
  public createClass = async (params: IClassParams) => await this.instance.put('/classes', params)

  /**
   * Update class.
   */
  public updateClass = async (id: string, params: IClassParams) => {
    return await this.instance.patch(`/class/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteClass = async (id: string) => await this.instance.delete(`/class/${id}`)

  /**
   * Groups.
   */
  public getGroups = async (queryParams?: IGroupsQueryParams) =>
    await this.instance.get('/groups', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getGroup = async (id: string) => await this.instance.get(`/group/${id}`)

  /**
   * Create class.
   */
  public createGroup = async (params: IGroupParams) => await this.instance.put('/groups', params)

  /**
   * Update class.
   */
  public updateGroup = async (id: string, params: IGroupParams) => {
    return await this.instance.patch(`/group/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteGroup = async (id: string) => await this.instance.delete(`/group/${id}`)

  /**
   * Brands.
   */
  public getBrands = async (queryParams?: IBrandsQueryParams) =>
    await this.instance.get('/brands', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getBrand = async (id: string) => await this.instance.get(`/brand/${id}`)

  /**
   * Create class.
   */
  public createBrand = async (params: IBrandParams) => await this.instance.put('/brands', params)

  /**
   * Update class.
   */
  public updateBrand = async (id: string, params: IBrandParams) => {
    return await this.instance.patch(`/brand/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteBrand = async (id: string) => await this.instance.delete(`/brand/${id}`)

  /**
   * Model.
   */
  public getModels = async (queryParams?: IModelsQueryParams) =>
    await this.instance.get('/models', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getModel = async (id: string) => await this.instance.get(`/model/${id}`)

  /**
   * Create class.
   */
  public createModel = async (params: IModelParams) => await this.instance.put('/models', params)

  /**
   * Update class.
   */
  public updateModel = async (id: string, params: IModelParams) => {
    return await this.instance.patch(`/model/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteModel = async (id: string) => await this.instance.delete(`/model/${id}`)

  /**
   * Model details.
   */
  public getModelDetails = async (queryParams?: IModelDetailsQueryParams) =>
    await this.instance.get('/model-details', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getModelDetail = async (id: string) => await this.instance.get(`/model-detail/${id}`)

  /**
   * Create class.
   */
  public createModelDetails = async (params: IModelDetailParams) => await this.instance.put('/model-details', params)

  /**
   * Update class.
   */
  public updateModelDetail = async (id: string, params: IModelDetailParams) => {
    return await this.instance.patch(`/model-detail/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteModelDetail = async (id: string) => await this.instance.delete(`/model-detail/${id}`)

  /**
   * Companies.
   */
  public getCompanies = async (queryParams?: ICompaniesQueryParams) =>
    await this.instance.get('/companies', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getCompany = async (id: string) => await this.instance.get(`/company/${id}`)

  /**
   * Create class.
   */
  public createCompany = async (params: ICompanyParams) => await this.instance.put('/companies', params)

  /**
   * Update class.
   */
  public updateCompany = async (id: string, params: ICompanyParams) => {
    return await this.instance.patch(`/company/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteCompany = async (id: string) => await this.instance.delete(`/company/${id}`)

  /**
   * Company vehicle groups.
   * @param queryParams
   */
  public getCompanyVehicleGroups = async (queryParams?: ICompanyVehicleGroupsQueryParams) =>
    await this.instance.get('/company-vehicle-groups', {
      params: queryParams
    })

  /**
   * Company vehicle classes.
   * @param queryParams
   */
  public getCompanyVehicleClasses = async (queryParams?: ICompanyVehicleClassesQueryParams) =>
    await this.instance.get('/company-vehicle-classes', {
      params: queryParams
    })

  /**
   * Vehicles.
   */
  public getVehicles = async (queryParams?: IVehiclesQueryParams) =>
    await this.instance.get('/vehicles', {
      params: queryParams
    })

  /**
   * Class.
   */
  public getVehicle = async (id: string) => await this.instance.get(`/vehicle/${id}`)

  /**
   * Create class.
   */
  public createVehicle = async (params: IVehicleParams) => await this.instance.put('/vehicles', params)

  /**
   * Update class.
   */
  public updateVehicle = async (id: string, params: IVehicleParams) => {
    return await this.instance.patch(`/vehicle/${id}`, params)
  }

  /**
   * Delete class.
   */
  public deleteVehicle = async (id: string) => await this.instance.delete(`/vehicle/${id}`)

  /**
   * Invoices.
   */
  public invoices = async (queryParams?: IInvoicesQueryParams) =>
    await this.instance.get('/invoices', {
      params: queryParams
    })

  /**
   * Create invoice.
   */
  public createInvoice = async (params: IInvoiceParams) => await this.instance.put('/invoices', params)

  /**
   * Invoice.
   */
  public invoice = async (id: string) => await this.instance.get(`/invoice/${id}`)

  /**
   * Update invoice.
   */
  public updateInvoice = async (id: string, params: IInvoiceParams) => {
    return await this.instance.patch(`/invoice/${id}`, params)
  }

  /**
   * Resend invoice email.
   */
  public resendInvoice = async (id: string) => {
    return await this.instance.get(`/invoice/${id}/resend-email`)
  }

  /**
   * Stop continually invoice.
   */
  public stopContinuallyInvoice = async (id: string) => await this.instance.patch(`/invoice/${id}/stop`)

  /**
   * Delete invoice.
   */
  public deleteInvoice = async (id: string) => await this.instance.delete(`/invoice/${id}`)


  /**
   * Delete Revenue Outsource Company.
   */
  public deleteROC = async (id: string) =>
    await this.instance.delete(`/revenue-outsource-company/${id}`)

  /**
   * Update Revenue Outsource Company.
   */
  public updateROC = async (id: string, params: IInvoiceParams) => {
    return await this.instance.patch(`/revenue-outsource-company/${id}`, params)
  }

  /**
   * Get Revenue Outsource Companies.
   */
  public getROCS = async (queryParams?: ICompaniesQueryParams) =>
    await this.instance.get('/revenue-outsource-companies', {
      params: queryParams
    })

  /**
   * Get Revenue Outsource Company.
   */
  public getROC = async (id: string) => await this.instance.get(`/revenue-outsource-company/${id}`)

  /**
   * Create Revenue Outsource Company.
   */
  public createROC = async (params: IInvoiceParams) =>
    await this.instance.put('/revenue-outsource-companies', params)
}
