import { Navigate } from "react-router-dom";
import { CustomRouteRoutePropsType } from "./types";

const PublicRouteRoute = (props: CustomRouteRoutePropsType) => {
  const { isAuthenticated, element } = props;

  if (!isAuthenticated) {
    return element;
  }

  return <Navigate to={ { pathname: '/' } }/>;
};

export default PublicRouteRoute;
