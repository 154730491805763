import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Group, LoadingOverlay, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { useAppDispatch, useAppSelector } from '../../../store'
import { EmployeeType } from '../../../store/types'
import { BackListButton, ContentBox, ItemActionsMenu } from '../../partials'
import { fetchShowEmployee, resetShowEmployee } from '../../../store/features/employees/showEmployeeSlice'
import { fetchDeleteEmployee, resetDeleteEmployee } from '../../../store/features/employees/deleteEmployeeSlice'

const Show = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [data, setData] = useState<EmployeeType | null>(null)
  const { isLoading, response, error } = useAppSelector(state => state.showEmployee)
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError
  } = useAppSelector(state => state.deleteEmployee)

  useEffect(() => {
    if (response === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowEmployee(id as string))
      })()
    }
  }, [response, data, id, dispatch])

  useEffect(() => {
    if (response !== null) {
      setData(response.data)

      if (data !== null) {
        dispatch(resetShowEmployee())
      }
    }
  }, [response, dispatch, data])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowEmployee())
          await dispatch(fetchShowEmployee(id))
        })()
      } else if (error.response.status === 404) {
        dispatch(resetShowEmployee())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(error.data.message)
        })

        navigate('/employees')
      } else {
        dispatch(resetShowEmployee())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/employees')
      }
    }
  }, [error, id, dispatch, t, navigate])

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetShowEmployee())
      dispatch(resetDeleteEmployee())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted')
      })

      navigate('/employees')
    }
  }, [deleteResponse, t, dispatch, navigate])

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetShowEmployee())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: deleteError.response.data ? deleteError.response.data.message : t('errors.unknown')
      })
    }
  }, [deleteError, t, dispatch])

  const handleDelete = async (id: string) => {
    await dispatch(fetchDeleteEmployee(id))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading} />
      {data && (
        <>
          <ContentBox title={t('content_actions.show')}>
            <h4>{t('id')}:</h4>
            <Text>{data.id}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>Company Name:</h4>
            <Text>{data?.company?.name}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>User Name:</h4>
            <Text>{data?.user?.name}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />
          </ContentBox>
          <ContentBox>
            <Grid>
              <Grid.Col span={6}>
                <BackListButton to="/employees" />
              </Grid.Col>
              <Grid.Col span={6}>
                <Group
                  position="right"
                  pr="md"
                >
                  <ItemActionsMenu
                    edit={`/employees/${data.id}/edit`}
                    onDelete={() => handleDelete(data.id)}
                  />
                </Group>
              </Grid.Col>
            </Grid>
          </ContentBox>
        </>
      )}
    </MainLayout>
  )
}

export default Show
