import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { ICompaniesParams, ICompaniesResponse, ICompaniesState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListCompanies = createAsyncThunk(
  'listCompanies/fetchListCompanies',
  async ({ query }: ICompaniesParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getCompanies(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: ICompaniesState = {
  isLoading: false,
  response: null,
  error: null
}

const listCompaniesSlice = createSlice({
  name: 'Companies/list',
  initialState,
  reducers: {
    resetListCompanies: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListCompanies.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListCompanies.fulfilled, (state, action: PayloadAction<ICompaniesResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListCompanies.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listCompaniesSlice.reducer
export const { resetListCompanies } = listCompaniesSlice.actions
