import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Center, Divider, Loader, Modal, Pagination, Table} from "@mantine/core";
import {useAppDispatch, useAppSelector} from "../../../store";
import {fetchListReportLogs, resetListReportLogs} from "../../../store/features/reportLogs/listReportLogsSlice";
import {IReportLogsQueryParams, UserType} from "../../../store/types";
import {DATETIME_FORMAT, DEFAULT_PER_PAGE} from "../../../types";
import Moment from "react-moment";

type PropsType = {
  user: UserType | null;
  onClose: () => void;
}

const ReportLogsModal = ({user, onClose}: PropsType) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch()
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState<string>(t('report_logs'));
  const [queryParams, setQueryParams] = useState<IReportLogsQueryParams>({
    page: 1,
    per: DEFAULT_PER_PAGE
  })
  const {isLoading, response} = useAppSelector(state => state.listReportLogs)

  useEffect(() => {
    if (user === null) {
      setOpened(false);
    } else {
      setOpened(true);
      setTitle(`${t('report_logs')} | ${user.last_name}, ${user.name} - ${user.email}`)
    }
  }, [user, t]);

  useEffect(() => {
    if (user !== null && response === null) {
      dispatch(fetchListReportLogs({id: user.id, query: queryParams}))
    }
  }, [user, response, dispatch, queryParams]);

  useEffect(() => {
    dispatch(resetListReportLogs())
  }, [dispatch, queryParams])

  const handlePageChange = (page: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      page
    }))
  }

  const handleClose = () => {
    dispatch(resetListReportLogs())
    setQueryParams({
      page: 1,
      per: DEFAULT_PER_PAGE
    })
    setOpened(false);
    onClose();
  }

  return (
    <Modal opened={opened}
           onClose={handleClose}
           title={title}
           size="lg"
           centered>
      {isLoading && (
        <Center>
          <Loader variant="bars"/>
        </Center>
      )}

      {response && (
        <>
          {response.data.count > 0 ? (
            <>
              <Table striped highlightOnHover>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>{t('date')}</th>
                </tr>
                </thead>
                <tbody>
                {response.data.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>
                      <Moment format={DATETIME_FORMAT}>{item.created_at}</Moment>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <Divider mb="sm"/>
              <Center>
                <Pagination size="sm"
                            total={response.data.pagination.last}
                            page={response.data.pagination.current}
                            onChange={handlePageChange}
                            withEdges/>
              </Center>
            </>
          ) : (
            <>{t('errors.content_not_found')}</>
          )}
        </>
      )}
    </Modal>
  )
}

export default ReportLogsModal
