import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IPackageParams, IPackageResponse, IPackageState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchCreatePackage = createAsyncThunk(
  'listPackages/fetchCreatePackage',
  async (params: IPackageParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.createPackage(params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IPackageState = {
  isLoading: false,
  response: null,
  error: null
}

const createPackageSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetCreatePackage: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCreatePackage.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchCreatePackage.fulfilled, (state, action: PayloadAction<IPackageResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchCreatePackage.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default createPackageSlice.reducer
export const { resetCreatePackage } = createPackageSlice.actions
