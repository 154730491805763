import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Divider, Grid, Select, SelectItem, TextInput} from '@mantine/core'
import {DatePicker} from '@mantine/dates'
import {useForm, yupResolver} from '@mantine/form'
import * as Yup from 'yup'
import {IFormProps, IFormRef} from '../../../types'
import {useAppDispatch, useAppSelector} from '../../../store'
import {fetchListCompanies} from '../../../store/features/companies/listCompanySlice'
import {fetchListBrands} from '../../../store/features/brands/listBrandSlice'
import {fetchListModels} from '../../../store/features/models/listModelSlice'
import {fetchListLocations, resetListLocations} from '../../../store/features/locations/listLocationsSlice'
import {fetchListModelDetails} from '../../../store/features/modelDetails/listModelDetailSlice'
import {
  fetchListCompanyVehicleGroups,
  resetListCompanyVehicleGroups
} from "../../../store/features/companyVehicleGroups/listCompanyVehicleGroupSlice";
import {
  fetchListCompanyVehicleClasses,
  resetListCompanyVehicleClasses
} from "../../../store/features/companyVehicleClasses/listCompanyVehicleClassSlice";

const Form = forwardRef(({id, type, initialValues, onSubmit}: IFormProps, ref: Ref<IFormRef>) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [companyOptions, setCompanyOptions] = useState<SelectItem[]>([])
  const [brandOptions, setBrandOptions] = useState<SelectItem[]>([])
  const [modelOptions, setModelOptions] = useState<SelectItem[]>([])
  const [groupOptions, setGroupOptions] = useState<SelectItem[]>([])
  const [locationOptions, setLocationOptions] = useState<SelectItem[]>([])
  const [classOptions, setClassOptions] = useState<SelectItem[]>([])
  const [modelDetailOptions, setModelDetailOptions] = useState<SelectItem[]>([])
  const [inServiceDate, setInServiceDate] = useState<any>(new Date())
  const [initialized, setInitialized] = useState<boolean>(false)
  const {isLoading: companyIsLoading, response: companyResponse} = useAppSelector(state => state.listCompany)
  const {
    isLoading: companyVehicleGroupIsLoading,
    response: companyVehicleGroupResponse
  } = useAppSelector(state => state.listCompanyVehicleGroup)
  const {
    isLoading: companyVehicleClassIsLoading,
    response: companyVehicleClassResponse
  } = useAppSelector(state => state.listCompanyVehicleClass)
  const {isLoading: brandIsLoading, response: brandResponse} = useAppSelector(state => state.listBrand)
  const {isLoading: modelIsLoading, response: modelResponse} = useAppSelector(state => state.listModel)
  const {isLoading: locationIsLoading, response: locationResponse} = useAppSelector(state => state.listLocations)
  const {isLoading: modelDetailIsLoading, response: modelDetailResponse} = useAppSelector(
    state => state.listModelDetail
  )

  const changeFormatDate = (date: any) => {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return `${year}-${month}-${day}`
  }
  // vin number regex
  const vinRegex = /^[A-HJ-NPR-Z0-9]{8}[0-9X][A-HJ-NPR-Z0-9]{8}$/
  const schema = Yup.object().shape({
    company: Yup.string().nullable().required(t('validation.required')),
    location: Yup.string().nullable().required(t('validation.required')),
    vin: Yup.string().trim().matches(vinRegex, 'Invalid vin code').required(t('validation.required')),
    unit: Yup.string().nullable().required(t('validation.required')),
    brand_name: Yup.string().nullable().required(t('validation.required')),
    model_name: Yup.string().nullable().required(t('validation.required')),
    model_detail_name: Yup.string().nullable().required(t('validation.required')),
    mileage: Yup.number().nullable().required(t('validation.required')),
    company_group: Yup.string().nullable(),
    company_class: Yup.string().nullable(),
    model_year: Yup.number().nullable(),
    color: Yup.string().nullable(),
    condition: Yup.string().nullable().required(t('validation.required')),
    in_service_date: Yup.string().nullable().required(t('validation.required')),
    tags: Yup.array()
  })
  const model_years = () => {
    const currentYear = new Date().getFullYear()
    const years: any[] = []
    for (let i = 0; i < 10; i++) {
      years.push({
        label: (currentYear - i).toString(),
        value: (currentYear - i).toString().toLocaleLowerCase()
      })
    }
    return years
  }
  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  useEffect(() => {
    if (companyResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListCompanies({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setCompanyOptions(
        companyResponse.data.items.map((company: any) => ({
          value: company.id,
          label: company.name
        }))
      )
    }
  }, [companyResponse, dispatch])

  useEffect(() => {
    if (brandResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListBrands({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setBrandOptions(
        brandResponse.data.items.map((brand: any) => ({
          value: brand.id,
          label: brand.name
        }))
      )
    }
  }, [brandResponse, dispatch])

  useEffect(() => {
    if (modelResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListModels({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setModelOptions(
        modelResponse.data.items.map((model: any) => ({
          value: model.id,
          label: model.name
        }))
      )
    }
  }, [modelResponse, dispatch])

  useEffect(() => {
    if (companyVehicleGroupResponse !== null) {
      setGroupOptions(
        companyVehicleGroupResponse.data.items.map((group: any) => ({
          value: group.id,
          label: group.name
        }))
      )
    }
  }, [companyVehicleGroupResponse, dispatch])

  useEffect(() => {
    if (locationResponse !== null) {
      setLocationOptions(
        locationResponse.data.items.map((location: any) => ({
          value: location.id,
          label: location.name
        }))
      )
    }
  }, [locationResponse, dispatch])

  useEffect(() => {
    if (companyVehicleClassResponse !== null) {
      setClassOptions(
        companyVehicleClassResponse.data.items.map((classes: any) => ({
          value: classes.id,
          label: classes.name
        }))
      )
    }
  }, [companyVehicleClassResponse, dispatch])

  useEffect(() => {
    if (modelDetailResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListModelDetails({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setModelDetailOptions(
        modelDetailResponse.data.items.map((classes: any) => ({
          value: classes.id,
          label: classes.name
        }))
      )
    }
  }, [modelDetailResponse, dispatch])

  useEffect(() => {
    if (
      !companyIsLoading &&
      !companyVehicleGroupIsLoading &&
      !companyVehicleClassIsLoading &&
      !locationIsLoading &&
      !brandIsLoading &&
      !modelIsLoading &&
      !modelDetailIsLoading &&
      !initialized
    ) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [
    companyIsLoading,
    companyVehicleGroupIsLoading,
    companyVehicleClassIsLoading,
    locationIsLoading,
    brandIsLoading,
    modelIsLoading,
    initialized,
    form,
    modelDetailIsLoading,
    initialValues
  ])

  const handleReset = () => {
    form.reset()
    setInitialized(false)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  const handleCompanyChange = async (value: string | null) => {
    form.setFieldValue('company', value)
    if (value) {
      await dispatch(
        fetchListLocations({
          query: {
            page: 1,
            per: 1000
          }
        })
      )

      await dispatch(
        fetchListCompanyVehicleGroups({
          query: {
            company_id: value,
            page: 1,
            per: 1000,
          }
        })
      )
    } else {
      dispatch(resetListLocations())
      setLocationOptions([])
      form.setFieldValue('location', null)

      dispatch(resetListCompanyVehicleGroups())
      setGroupOptions([])
      form.setFieldValue('company_group', null)
    }
  }

  const handleGroupChange = async (value: string | null) => {
    form.setFieldValue('company_group', value)

    if (value) {
      await dispatch(
        fetchListCompanyVehicleClasses({
          query: {
            company_id: form.values.company,
            group_id: value,
            page: 1,
            per: 1000
          }
        })
      )
    } else {
      dispatch(resetListCompanyVehicleClasses())
      setClassOptions([])
      form.setFieldValue('company_class', null)
    }
  }

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => onSubmit(schema.cast(data)))}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('company')}
            placeholder={t('company')}
            data={companyOptions}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            required
            {...form.getInputProps('company')}
            onChange={value => handleCompanyChange(value)}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('location')}
            placeholder={t('location')}
            data={locationOptions}
            disabled={locationIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            required
            {...form.getInputProps('location')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('vin')}
            placeholder={t('vin')}
            required
            {...form.getInputProps('vin')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('unit')}
            placeholder={t('unit')}
            required
            {...form.getInputProps('unit')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('brand')}
            placeholder={t('brand')}
            data={brandOptions}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('brand_name')}
          />
        </Grid.Col>

        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('model')}
            placeholder={t('model')}
            data={modelOptions}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('model_name')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('model_year')}
            placeholder={t('model_year')}
            data={model_years()}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            required
            {...form.getInputProps('model_year')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('model_detail')}
            placeholder={t('model_detail')}
            data={modelDetailOptions}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('model_detail_name')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('group')}
            placeholder={t('group')}
            data={groupOptions}
            disabled={companyVehicleGroupIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('company_group')}
            onChange={value => handleGroupChange(value)}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('class')}
            placeholder={t('class')}
            data={classOptions}
            disabled={companyVehicleClassIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('company_class')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            type="number"
            label={t('mileage')}
            placeholder={t('mileage')}
            required
            {...form.getInputProps('mileage')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('condition')}
            placeholder={t('condition')}
            data={[
              {value: 'fair', label: t('conditions.fair')},
              {value: 'good', label: t('conditions.good')},
              {value: 'excellent', label: t('conditions.excellent')}
            ]}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            required
            {...form.getInputProps('condition')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <DatePicker
            placeholder={t('in_service_date')}
            label={t('in_service_date')}
            defaultValue={new Date()}
            value={inServiceDate}
            onChange={(val: any) => {
              setInServiceDate(changeFormatDate(val))
              form.setFieldValue('in_service_date', changeFormatDate(val))
            }}
            inputFormat="DD/MM/YYYY"
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            type="text"
            label={t('color')}
            placeholder={t('color')}
            {...form.getInputProps('color')}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
