import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IVehicleParams, IVehicleResponse, IVehicleState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateVehicle = createAsyncThunk(
  'listVehicles/fetchUpdateVehicle',
  async ({ id, params }: { id: string; params: IVehicleParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateVehicle(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IVehicleState = {
  isLoading: false,
  response: null,
  error: null
}

const updateVehicleSlice = createSlice({
  name: 'vehicles/update',
  initialState,
  reducers: {
    resetUpdateVehicle: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateVehicle.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateVehicle.fulfilled, (state, action: PayloadAction<IVehicleResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateVehicle.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateVehicleSlice.reducer
export const { resetUpdateVehicle } = updateVehicleSlice.actions
