import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { ILocationsParams, ILocationsResponse, ILocationsState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListLocations = createAsyncThunk(
  'listLocations/fetchListLocations',
  async ({ query }: ILocationsParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.locations(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: ILocationsState = {
  isLoading: false,
  response: null,
  error: null
}

const listLocationsSlice = createSlice({
  name: 'locations/list',
  initialState,
  reducers: {
    resetListLocations: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListLocations.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListLocations.fulfilled, (state, action: PayloadAction<ILocationsResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListLocations.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listLocationsSlice.reducer
export const { resetListLocations } = listLocationsSlice.actions
