import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import { IUsersParams, IUsersResponse, IUsersState } from "../../types";

const adminApiClient = AdminApiClient.getInstance();

export const fetchListUsers = createAsyncThunk('listUsers/fetchListUsers', async (
  { query }: IUsersParams, { rejectWithValue }
) => {
  try {
    const response = await adminApiClient.users(query);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IUsersState = {
  isLoading: false,
  response: null,
  error: null,
};

const listUsersSlice = createSlice({
  name: 'users/list',
  initialState,
  reducers: {
    resetListUsers: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchListUsers.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchListUsers.fulfilled, (state, action: PayloadAction<IUsersResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchListUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default listUsersSlice.reducer;
export const { resetListUsers } = listUsersSlice.actions;
