import { AxiosResponse, AxiosError } from "axios";
import AbstractHttpClient from "./AbstractHttpClient";
import { app } from "../../config";

export default class SharedApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: SharedApiClient;

  /**
   * @private constructor
   */
  private constructor() {
    super(app.API_URL.replace(/^\/|\/$/g, ''));

    this._initializeResponseInterceptor();
  }

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => response;

  /**
   * @protected _handleError
   * @param error
   */
  protected _handleError = async (error: AxiosError) => Promise.reject(error);

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this();
    }

    return this.classInstance;
  }

  /**
   * States.
   */
  public states = async () => await this.instance.get('/states');

  /**
   * Cities.
   */
  public cities = async (stateId: string) => await this.instance.get(`/cities/${ stateId }`);
}
