import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { IFinancingCompanyParams, FinancingCompanyType } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import {
  fetchShowFinancingCompany,
  resetShowFinancingCompany
} from '../../../store/features/financingCompanies/showFinancingCompanySlice'
import {
  fetchUpdateFinancingCompany,
  resetUpdateFinancyCompany
} from '../../../store/features/financingCompanies/updateFinancingCompanySlice'

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<IFinancingCompanyParams | null>(null)

  const {
    isLoading: packageIsLoading,
    response: packageResponse,
    error: packageError
  } = useAppSelector(state => state.showFinancingCompany)
  const { isLoading, response, error } = useAppSelector(state => state.updateFinancingCompany)

  useEffect(() => {
    if (packageResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowFinancingCompany(id as string))
      })()
    }
  }, [packageResponse, data, id, dispatch])

  const getSerializedData = (data: FinancingCompanyType) => {
    return {
      name: data.name
    }
  }

  useEffect(() => {
    if (packageResponse !== null) {
      setData(getSerializedData(packageResponse.data))

      if (data !== null) {
        dispatch(resetShowFinancingCompany())
      }
    }
  }, [packageResponse, data, dispatch])

  useEffect(() => {
    if (packageError !== null) {
      if (packageError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowFinancingCompany())
          await dispatch(fetchShowFinancingCompany(id))
        })()
      } else if (packageError.response.status === 404) {
        dispatch(resetShowFinancingCompany())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(packageError.data.message)
        })

        navigate('/financing-companies')
      } else {
        dispatch(resetShowFinancingCompany())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/financing-companies')
      }
    }
  }, [packageError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdateFinancyCompany())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdateFinancyCompany())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (params: IFinancingCompanyParams) => {
    await dispatch(fetchUpdateFinancingCompany({ id: id as string, params }))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={packageIsLoading || isLoading || !data} />
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-financing-companies-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/financing-companies" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-financing-companies-form" />
              <ResetButton form="edit-financing-companies-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
