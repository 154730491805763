import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { IClassParams, ClassType } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchShowClass, resetShowClass } from '../../../store/features/cls/showClassSlice'
import { fetchUpdateClass, resetUpdateClass } from '../../../store/features/cls/updateClassSlice'

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<IClassParams | null>(null)

  const {
    isLoading: classIsLoading,
    response: classResponse,
    error: classError
  } = useAppSelector(state => state.showClass)
  const { isLoading, response, error } = useAppSelector(state => state.updateClass)

  useEffect(() => {
    if (classResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowClass(id as string))
      })()
    }
  }, [classResponse, data, id, dispatch])

  const getSerializedData = (data: ClassType) => {
    return {
      name: data.name
    }
  }

  useEffect(() => {
    if (classResponse !== null) {
      setData(getSerializedData(classResponse.data))

      if (data !== null) {
        dispatch(resetShowClass())
      }
    }
  }, [classResponse, data, dispatch])

  useEffect(() => {
    if (classError !== null) {
      if (classError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowClass())
          await dispatch(fetchShowClass(id))
        })()
      } else if (classError.response.status === 404) {
        dispatch(resetShowClass())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(classError.data.message)
        })

        navigate('/classes')
      } else {
        dispatch(resetShowClass())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/classes')
      }
    }
  }, [classError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdateClass())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdateClass())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (params: IClassParams) => {
    await dispatch(fetchUpdateClass({ id: id as string, params }))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={classIsLoading || isLoading || !data} />
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-class-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/classes" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-class-form" />
              <ResetButton form="edit-class-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
