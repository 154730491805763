export enum FormTypes {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum RoleTypes {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
}

export enum GenderTypes {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum PaymentProviderTypes {
  STRIPE = 'stripe',
}

export enum TransactionStatusType {
  WAITING = 'waiting',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
}

export enum TransactionStatusColorType {
  WAITING = 'yellow',
  SUCCEEDED = 'green',
  FAILED = 'red',
  REFUNDED = 'orange',
  CANCELLED = 'red',
}

export enum RegionTypes {
  NA = 'NA',
  SE = 'SE',
  NE = 'NE',
  MW = 'MW',
  SW = 'SW',
  WC = 'WC',
}
