import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IInvoiceParams, IInvoiceResponse, IInvoiceState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchCreateInvoice = createAsyncThunk(
  'listInvoices/fetchCreateInvoice',
  async (params: IInvoiceParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.createInvoice(params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IInvoiceState = {
  isLoading: false,
  response: null,
  error: null
}

const createInvoiceSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetCreateInvoice: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCreateInvoice.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchCreateInvoice.fulfilled, (state, action: PayloadAction<IInvoiceResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchCreateInvoice.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default createInvoiceSlice.reducer
export const { resetCreateInvoice } = createInvoiceSlice.actions
