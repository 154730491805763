import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Divider, Grid, Select, SelectItem, NumberInput, Checkbox, InputWrapper} from '@mantine/core'
import {useForm, yupResolver} from '@mantine/form'
import {RichTextEditor} from "@mantine/rte";
import {CurrencyDollar as IconCurrencyDollar} from 'tabler-icons-react'
import * as Yup from 'yup'
import {IFormProps, IFormRef} from '../../../types'
import {useAppDispatch, useAppSelector} from '../../../store'
import {fetchListCities} from '../../../store/features/shared/listCitiesSlice'
import {fetchListCompanies} from "../../../store/features/companies/listCompanySlice";

const Form = forwardRef(({id, type, initialValues, onSubmit}: IFormProps, ref: Ref<IFormRef>) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [companyOptions, setCompanyOptions] = useState<SelectItem[]>([])

  const {response: companyResponse} = useAppSelector(state => state.listCompany)

  const schema = Yup.object().shape({
    company: Yup.string().nullable().required(t('validation.required')),
    amount: Yup.number().nullable().required(t('validation.required')),
    months: Yup.number().nullable().required(t('validation.required')),
    continually: Yup.boolean().nullable().transform(value => (value === '' ? false : value)),
    description: Yup.string().nullable().transform(value => (value === null ? null : value)),
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  useEffect(() => {
    if (companyResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListCompanies({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setCompanyOptions(
        companyResponse.data.items.map((user: any) => ({
          value: user.id,
          label: user.name
        }))
      )
    }
  }, [companyResponse, dispatch])

  useEffect(() => {
    if (initialValues.state) {
      ;(async () => {
        await dispatch(fetchListCities(initialValues.state))
      })()
    }
  }, [initialValues, dispatch])


  const handleReset = () => {
    form.reset()
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => onSubmit(schema.cast(data)))}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('company')}
            placeholder={t('company')}
            data={companyOptions}
            searchable
            nothingFound={t('errors.no_options')}
            required
            clearable
            {...form.getInputProps('company')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            icon={<IconCurrencyDollar size={16}/>}
            label={t('amount')}
            placeholder="99.90"
            min={1}
            precision={2}
            required
            {...form.getInputProps('amount')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label={t('months')}
            min={1}
            max={12}
            placeholder="1"
            required
            {...form.getInputProps('months')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col sm={12}>
          <InputWrapper label={t('description')}>
            <RichTextEditor

              placeholder={t('description')}
              {...form.getInputProps('description')}/>
          </InputWrapper>
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={4}
          lg={3}
        >
          <Checkbox
            label={t('continually')}
            {...form.getInputProps('continually', {type: 'checkbox'})}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
