import {
  Dashboard as DashboardIcon,
  Users as UsersIcon,
  Package as PackageIcon,
  BuildingBank as BuildingBankIcon,
  Car as CarIcon,
  ChartPie3 as ChartPie3Icon,
  Atom2 as Atom2Icon,
  BrandVercel as BrandVercelIcon,
  BoxModel2 as BoxModel2Icon,
  BoxModel as BoxModelIcon,
  BuildingSkyscraper as BuildingSkyscraperIcon,
  Location as LocationIcon,
  UserCircle as UserCircleIcon,
  FileInvoice as FileInvoiceIcon,
} from 'tabler-icons-react'

export type NavigationItem = {
  label: string // Translation key
  to: string // Router path
  icon?: any // Tabler icon
  children?: Array<NavigationItem>
}

const navigation: Array<NavigationItem> = [
  {
    label: 'dashboard',
    to: '/',
    icon: DashboardIcon
  },
  {
    label: 'users',
    to: '/users',
    icon: UsersIcon
  },
  {
    label: 'packages',
    to: '/packages',
    icon: PackageIcon
  },
  {
    label: 'financing_companies',
    to: '/financing-companies',
    icon: BuildingBankIcon
  },
  {
    label: 'vehicles',
    to: '/vehicles',
    icon: CarIcon
  },
  {
    label: 'classes',
    to: '/classes',
    icon: ChartPie3Icon
  },
  {
    label: 'groups',
    to: '/groups',
    icon: Atom2Icon
  },
  {
    label: 'brands',
    to: '/brands',
    icon: BrandVercelIcon
  },
  {
    label: 'models',
    to: '/models',
    icon: BoxModel2Icon
  },
  {
    label: 'model_details',
    to: '/model-details',
    icon: BoxModelIcon
  },
  {
    label: 'companies',
    to: '/companies',
    icon: BuildingSkyscraperIcon
  },
  {
    label: 'locations',
    to: '/locations',
    icon: LocationIcon
  },
  {
    label: 'employees',
    to: '/employees',
    icon: UserCircleIcon
  },
  {
    label: 'invoices',
    to: '/invoices',
    icon: FileInvoiceIcon
  },
  {
    label: 'revenue_outsource_companies',
    to: '/revenue-outsource-companies',
    icon: BuildingBankIcon
  }
]

export default navigation
