import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox, Grid, Textarea, TextInput, NumberInput, Divider} from '@mantine/core'
import {useForm, yupResolver} from '@mantine/form'
import * as Yup from 'yup'
import {IFormProps, IFormRef} from '../../../types'
import {CurrencyDollar as IconCurrencyDollar} from "tabler-icons-react";

const Form = forwardRef(({id, type, initialValues, onSubmit}: IFormProps, ref: Ref<IFormRef>) => {
  const {t} = useTranslation()
  const formRef = useRef<HTMLFormElement>(null)
  const [initialized, setInitialized] = useState<boolean>(false)

  const schema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    description: Yup.string().required(t('validation.required')),
    fleet_size: Yup.number().nullable().default(null),
    manheim_query_limit: Yup.number().nullable().default(null),
    annual_fee: Yup.number().required(t('validation.required')),
    monthly_fee: Yup.number().required(t('validation.required')),
    is_demo: Yup.boolean().required(t('validation.required'))
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  useEffect(() => {
    if (!initialized) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [initialized, form, initialValues])

  const handleReset = () => {
    form.reset()
    setInitialized(false)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => {
        onSubmit(schema.cast(data))
      })}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('pages.package.name')}
            placeholder="Package"
            required
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Textarea
            label={t('pages.package.description')}
            placeholder="Description"
            required
            {...form.getInputProps('description')}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label={t('pages.package.fleet_size')}
            min={0}
            {...form.getInputProps('fleet_size')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label={t('pages.package.manheim_query_limit')}
            min={0}
            {...form.getInputProps('manheim_query_limit')}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            icon={<IconCurrencyDollar size={16}/>}
            label={t('pages.package.annual_fee')}
            min={0}
            precision={2}
            required
            {...form.getInputProps('annual_fee')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            icon={<IconCurrencyDollar size={16}/>}
            label={t('pages.package.monthly_fee')}
            min={0}
            precision={2}
            required
            {...form.getInputProps('monthly_fee')}
          />
        </Grid.Col>
      </Grid>
      <Divider my="md"/>
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Checkbox
            label={t('pages.package.is_demo')}
            {...form.getInputProps('is_demo', {type: 'checkbox'})}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
