import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IEmployeeParams, IEmployeeResponse, IEmployeeState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateEmployee = createAsyncThunk(
  'listEmployees/fetchUpdateEmployee',
  async ({ id, params }: { id: string; params: IEmployeeParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateEmployee(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IEmployeeState = {
  isLoading: false,
  response: null,
  error: null
}

const updateEmployeeSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateEmployee: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateEmployee.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateEmployee.fulfilled, (state, action: PayloadAction<IEmployeeResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateEmployee.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateEmployeeSlice.reducer
export const { resetUpdateEmployee } = updateEmployeeSlice.actions
