import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { ICompanyParams, ICompanyResponse, ICompanyState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateCompany = createAsyncThunk(
  'listCompanies/fetchUpdateCompany',
  async ({ id, params }: { id: string; params: ICompanyParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateCompany(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: ICompanyState = {
  isLoading: false,
  response: null,
  error: null
}

const updateCompanySlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateCompany: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateCompany.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateCompany.fulfilled, (state, action: PayloadAction<ICompanyResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateCompany.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateCompanySlice.reducer
export const { resetUpdateCompany } = updateCompanySlice.actions
