import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IModelResponse, IModelState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchShowModel = createAsyncThunk('listModels/fetchShowModel', async (id: string, { rejectWithValue }) => {
  try {
    const response = await adminApiClient.getModel(id)
    return response.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error)
  }
})

const initialState: IModelState = {
  isLoading: false,
  response: null,
  error: null
}

const showModelSlice = createSlice({
  name: 'models/show',
  initialState,
  reducers: {
    resetShowModel: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchShowModel.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchShowModel.fulfilled, (state, action: PayloadAction<IModelResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchShowModel.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default showModelSlice.reducer
export const { resetShowModel } = showModelSlice.actions
