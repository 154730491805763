import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import qs from 'query-string'
import {useForm} from '@mantine/form'
import {showNotification} from '@mantine/notifications'
import {
  Badge,
  Button,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  MediaQuery,
  Pagination,
  Select,
  Table,
  Text,
  TextInput
} from '@mantine/core'
import {useModals} from "@mantine/modals";
import {Search as SearchIcon, SquarePlus as SquarePlusIcon} from 'tabler-icons-react'
import Moment from "react-moment";
import {MainLayout} from '../../layouts'
import {useAppDispatch, useAppSelector} from '../../../store'
import {IUsersQueryParams, UserType} from '../../../store/types'
import {DATETIME_FORMAT, DEFAULT_PER_PAGE, SELECT_PER_PAGE_OPTIONS, SELECT_ROLE_OPTIONS} from '../../../types'
import {fetchListUsers} from '../../../store/features/users/listUsersSlice'
import {fetchDeleteUser, resetDeleteUser} from '../../../store/features/users/deleteUserSlice'
import {ContentBox, ContentNotFound, UserItemActionsMenu} from '../../partials'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import {app} from "../../../config";
import ReportLogsModal from "./ReportLogsModal";

const List = () => {
  const {t} = useTranslation()
  const {search} = useLocation()
  const modals = useModals();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const adminApiClient = AdminApiClient.getInstance()
  const [reportLogsUser, setReportLogsUser] = useState<UserType | null>(null);
  const [queryParams, setQueryParams] = useState<IUsersQueryParams>({
    page: 1,
    per: DEFAULT_PER_PAGE,
    ...qs.parse(search)
  })
  const [isReportsSending, setIsReportsSending] = useState(false);

  const {isLoading, response, error} = useAppSelector(state => state.listUsers)
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError
  } = useAppSelector(state => state.deleteUser)

  const filterForm = useForm({
    initialValues: {
      role: queryParams.role,
      q: queryParams.q || '',
      per: queryParams.per
    }
  })

  const handleFilterSubmit = async (data: IUsersQueryParams) => {
    setQueryParams({...queryParams, ...data, page: 1})
    navigate(`/users?${qs.stringify(data)}`, {replace: true})
  }

  useEffect(() => {
    dispatch(fetchListUsers({query: queryParams}))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (error !== null && error.response.status === 401) {
      dispatch(fetchListUsers({query: queryParams}))
    }
  }, [error, dispatch, queryParams])

  const handleChangePage = (page: number) => {
    if (page !== Number(queryParams.page)) {
      setQueryParams({...queryParams, page})
      navigate(`/users?${qs.stringify({...queryParams, page})}`, {replace: true})
    }
  }

  const handleDelete = async (id: string) => {
    dispatch(fetchDeleteUser(id))
  }

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetDeleteUser())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted')
      })

      setQueryParams({...queryParams, page: 1})
      navigate(`/users?${qs.stringify({...queryParams, page: 1})}`, {replace: true})
    }
  }, [deleteResponse, queryParams, t, dispatch, navigate])

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetDeleteUser())

      showNotification({
        color: 'red',
        title: t('successful'),
        message: deleteError.response ? deleteError.response.message : t('errors.unknown')
      })
    }
  }, [deleteError, t, dispatch])

  const impersonateUser = async (id: string) => {
    try {
      const response = await adminApiClient.impersonateUser(id)
      const token = await response.data.data.token
      if (token) {
        const url = `${app.FRONTEND_BASE_URL}?token=${token}`
        window.open(url, '_blank')
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const sendReports = async (id: string) => {
    setIsReportsSending(true)
    try {
      await adminApiClient.sendReportsUser(id)
      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('reports_sent')
      })
    } catch (error: any) {
      showNotification({
        color: 'red',
        title: t('error'),
        message: t('reports_could_not_be_sent')
      })
    } finally {
      setIsReportsSending(false)
    }
  }

  const handleSendReports = async (id: string) => {
    modals.openConfirmModal({
      title: t('confirmation_required'),
      centered: true,
      children: <Text size="sm">{t('do_you_want_to_send')}</Text>,
      labels: {confirm: t('yes'), cancel: t('no')},
      confirmProps: {color: 'red'},
      onCancel: () => {
      },
      onConfirm: async () => await sendReports(id)
    })
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading || isReportsSending}/>
      {response && (
        <>
          <MediaQuery
            largerThan="md"
            styles={{flexDirection: 'row-reverse'}}
          >
            <Grid gutter="sm">
              <Grid.Col
                lg={3}
                md={4}
                sm={12}
              >
                <ContentBox title={t('filter')}>
                  <form onSubmit={filterForm.onSubmit(handleFilterSubmit)}>
                    <Select
                      data={SELECT_ROLE_OPTIONS}
                      label={t('role')}
                      placeholder={t('select')}
                      searchable
                      nothingFound={t('errors.no_options')}
                      clearable
                      mb="xs"
                      {...filterForm.getInputProps('role')}
                    />
                    <TextInput
                      placeholder={t('keywords')}
                      label={t('keywords')}
                      mb="xs"
                      {...filterForm.getInputProps('q')}
                    />
                    <Select
                      data={SELECT_PER_PAGE_OPTIONS}
                      label={t('per_page')}
                      placeholder={t('select')}
                      mb="xs"
                      {...filterForm.getInputProps('per')}
                    />
                    <Button
                      type="submit"
                      leftIcon={<SearchIcon size={16}/>}
                      variant="outline"
                      color="cyan"
                      fullWidth
                    >
                      {t('filter')}
                    </Button>
                  </form>
                </ContentBox>
              </Grid.Col>
              <Grid.Col
                lg={9}
                md={8}
                sm={12}
              >
                <ContentBox
                  title={t('users')}
                  subtitle={`${t('total')}: ${response.data.count}`}
                  withoutSpacing
                >
                  {response.data.items.length > 0 ? (
                    <div className="table-responsive">
                      <Table
                        striped
                        highlightOnHover
                      >
                        <thead>
                        <tr>
                          <th>{t('name')}</th>
                          <th>{t('lastname')}</th>
                          <th>{t('email')}</th>
                          <th className="text-center">{t('roles')}</th>
                          <th className="text-center">{t('last_login_at')}</th>
                          <th className="text-center">{t('actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {response.data.items.map(user => (
                          <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.email}</td>
                            <td className="text-center">
                              <Container ml="xs">
                                {user.roles.map((role, key) => (
                                  <Badge
                                    variant="outline"
                                    key={key}
                                    mr="xs"
                                    color="gray"
                                  >
                                    {t(`role_names.${role}`)}
                                  </Badge>
                                ))}
                              </Container>
                            </td>
                            <td className="text-center">
                              {user.last_login_at ?
                                <Moment format={DATETIME_FORMAT}>{user.last_login_at}</Moment> :
                                <>-</>}
                            </td>
                            <td className="text-center">
                              <UserItemActionsMenu
                                show={`/users/${user.id}`}
                                edit={`/users/${user.id}/edit`}
                                impersonate={() => impersonateUser(user.id)}
                                showReportLogs={() => setReportLogsUser(user)}
                                sendReports={() => handleSendReports(user.id)}
                                onDelete={() => handleDelete(user.id)}
                              />
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <ContentNotFound/>
                  )}
                </ContentBox>
                <ContentBox>
                  <Grid>
                    <Grid.Col span={8}>
                      {response.data.pagination.last > 1 && (
                        <Pagination
                          page={Number(queryParams.page)}
                          onChange={handleChangePage}
                          total={response.data.pagination.last}
                        />
                      )}
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Group position="right">
                        <Button
                          leftIcon={<SquarePlusIcon size={16}/>}
                          component={Link}
                          to="/users/create"
                          variant="outline"
                          color="green"
                        >
                          {t('content_actions.create')}
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>
                </ContentBox>
              </Grid.Col>
            </Grid>
          </MediaQuery>
          <ReportLogsModal user={reportLogsUser} onClose={() => setReportLogsUser(null)}/>
        </>
      )}
    </MainLayout>
  )
}

export default List
