import {combineReducers} from '@reduxjs/toolkit'
import {loginSlice} from './auth'
import {meSlice} from './userOperations'
import {listStatesSlice, listCitiesSlice} from './shared'
import {listUsersSlice, createUserSlice, updateUserSlice, showUserSlice, deleteUserSlice} from './users'
import {
  listLocationsSlice,
  createLocationSlice,
  updateLocationSlice,
  showLocationSlice,
  deleteLocationSlice
} from './locations'
import {
  listEmployeesSlice,
  createEmployeeSlice,
  updateEmployeeSlice,
  showEmployeeSlice,
  deleteEmployeeSlice
} from './employees'
import {listGroupSlice, createGroupSlice, updateGroupSlice, showGroupSlice, deleteGroupSlice} from './groups'
import {listClassSlice, createClassSlice, updateClassSlice, showClassSlice, deleteClassSlice} from './cls'
import {listBrandSlice, createBrandSlice, updateBrandSlice, showBrandSlice, deleteBrandSlice} from './brands'
import {listModelSlice, createModelSlice, updateModelSlice, showModelSlice, deleteModelSlice} from './models'
import {
  listVehicleSlice,
  createVehicleSlice,
  updateVehicleSlice,
  showVehicleSlice,
  deleteVehicleSlice
} from './vehicles'
import {
  listCompanySlice,
  createCompanySlice,
  updateCompanySlice,
  showCompanySlice,
  deleteCompanySlice
} from './companies'
import {listCompanyVehicleGroupSlice} from './companyVehicleGroups'
import {listCompanyVehicleClassSlice} from './companyVehicleClasses'
import {
  listModelDetailSlice,
  createModelDetailSlice,
  updateModelDetailSlice,
  showModelDetailSlice,
  deleteModelDetailSlice
} from './modelDetails'
import {
  listPackagesSlice,
  deletePackageSlice,
  showPackageSlice,
  updatePackageSlice,
  createPackageSlice
} from './packages'
import {
  listFinancingCompaniesSlice,
  showFinancingCompanySlice,
  deleteFinancingCompanySlice,
  createFinancingCompanySlice,
  updateFinancingCompanySlice
} from './financingCompanies'
import {
  listInvoicesSlice,
  createInvoiceSlice,
  showInvoiceSlice,
  stopContinuallyInvoiceSlice,
  resendInvoiceSlice,
  updateInvoiceSlice,
  deleteInvoiceSlice
} from "./invoices";
import { 
  listROCSlice,
  createROCSlice,
  updateROCSlice,
  showROCSlice,
  deleteROCSlice
} from './revenueOutsourceCompanies'
import {listReportLogsSlice} from "./reportLogs";

const reducer = combineReducers({
  login: loginSlice,
  me: meSlice,
  listStates: listStatesSlice,
  listCities: listCitiesSlice,
  // users
  listUsers: listUsersSlice,
  createUser: createUserSlice,
  updateUser: updateUserSlice,
  showUser: showUserSlice,
  deleteUser: deleteUserSlice,
  // locations
  listLocations: listLocationsSlice,
  createLocation: createLocationSlice,
  updateLocation: updateLocationSlice,
  showLocation: showLocationSlice,
  deleteLocation: deleteLocationSlice,
  // employees
  listEmployees: listEmployeesSlice,
  createEmployee: createEmployeeSlice,
  updateEmployee: updateEmployeeSlice,
  showEmployee: showEmployeeSlice,
  deleteEmployee: deleteEmployeeSlice,
  // classes
  listClass: listClassSlice,
  createClass: createClassSlice,
  updateClass: updateClassSlice,
  showClass: showClassSlice,
  deleteClass: deleteClassSlice,
  // packages
  listPackages: listPackagesSlice,
  deletePackage: deletePackageSlice,
  showPackage: showPackageSlice,
  updatePackage: updatePackageSlice,
  createPackage: createPackageSlice,
  // financing companies
  listFinancingCompanies: listFinancingCompaniesSlice,
  showFinancingCompany: showFinancingCompanySlice,
  deleteFinancingCompany: deleteFinancingCompanySlice,
  createFinancingCompany: createFinancingCompanySlice,
  updateFinancingCompany: updateFinancingCompanySlice,

  // groups
  listGroup: listGroupSlice,
  createGroup: createGroupSlice,
  updateGroup: updateGroupSlice,
  showGroup: showGroupSlice,
  deleteGroup: deleteGroupSlice,

  // brands
  listBrand: listBrandSlice,
  createBrand: createBrandSlice,
  updateBrand: updateBrandSlice,
  showBrand: showBrandSlice,
  deleteBrand: deleteBrandSlice,

  // model
  listModel: listModelSlice,
  createModel: createModelSlice,
  updateModel: updateModelSlice,
  showModel: showModelSlice,
  deleteModel: deleteModelSlice,

  // vehicles
  listVehicle: listVehicleSlice,
  createVehicle: createVehicleSlice,
  updateVehicle: updateVehicleSlice,
  showVehicle: showVehicleSlice,
  deleteVehicle: deleteVehicleSlice,

  // model
  listModelDetail: listModelDetailSlice,
  createModelDetail: createModelDetailSlice,
  updateModelDetail: updateModelDetailSlice,
  showModelDetail: showModelDetailSlice,
  deleteModelDetail: deleteModelDetailSlice,

  // companies
  listCompany: listCompanySlice,
  createCompany: createCompanySlice,
  updateCompany: updateCompanySlice,
  showCompany: showCompanySlice,
  deleteCompany: deleteCompanySlice,

  // company vehicle groups
  listCompanyVehicleGroup: listCompanyVehicleGroupSlice,

  // company vehicle classes
    listCompanyVehicleClass: listCompanyVehicleClassSlice,

  // invoices
  listInvoices: listInvoicesSlice,
  createInvoice: createInvoiceSlice,
  showInvoice: showInvoiceSlice,
  updateInvoice: updateInvoiceSlice,
  stopContinually: stopContinuallyInvoiceSlice,
  resendInvoice: resendInvoiceSlice,
  deleteInvoice: deleteInvoiceSlice,

  // report logs
  listReportLogs: listReportLogsSlice,

  //Revenue Outsource Companies
  listROC: listROCSlice,
  createROC: createROCSlice,
  updateROC: updateROCSlice,
  showROC: showROCSlice,
  deleteROC: deleteROCSlice,
})

export default reducer
