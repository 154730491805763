const app = {
  APP_NAME: String(process.env.REACT_APP_NAME),
  ENV: String(process.env.REACT_APP_ENV),
  STORAGE_URL: String(process.env.REACT_APP_STORAGE_URL),
  TIMEZONE: String(process.env.REACT_APP_TIMEZONE),
  DEFAULT_LOCALE: String(process.env.REACT_APP_DEFAULT_LOCALE),
  API_URL: String(process.env.REACT_APP_API_URL),
  BASE_URL: String(process.env.REACT_APP_BASE_URL),
  FRONTEND_BASE_URL: String(process.env.REACT_APP_FRONTEND_BASE_URL),
};

export default app;
