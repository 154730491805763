import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import { IDeleteState, IResponse } from "../../types";

const sharedApiClient = AdminApiClient.getInstance();

export const fetchDeleteUser = createAsyncThunk('listUsers/fetchDeleteUser', async (
  id: string, { rejectWithValue }
) => {
  try {
    const response = await sharedApiClient.deleteUser(id);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IDeleteState = {
  isLoading: false,
  response: null,
  error: null,
}

const deleteUserSlice = createSlice({
  name: 'users/delete',
  initialState,
  reducers: {
    resetDeleteUser: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchDeleteUser.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchDeleteUser.fulfilled, (state, action: PayloadAction<IResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchDeleteUser.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default deleteUserSlice.reducer;
export const { resetDeleteUser } = deleteUserSlice.actions;
