import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import {
  AppShell,
  Burger,
  Footer,
  Header,
  MediaQuery,
  Navbar,
  useMantineTheme,
  Text,
  createStyles,
  Image,
  Menu,
  UnstyledButton,
  Group,
  Avatar,
  ScrollArea,
  ThemeIcon
} from '@mantine/core'
import { Logout as LogoutIcon, UserCircle as UserCircleIcon } from 'tabler-icons-react'
import moment from "moment";
import ILayoutProps from './ILayout'
import navigation, { NavigationItem } from '../../config/navigation'
import { Breadcrumb, LocaleChanger, ThemeChanger } from '../partials'
import { useAppDispatch, useAppSelector } from '../../store'
import { logout } from '../../store/features/auth/loginSlice'
import { resetMe } from '../../store/features/userOperations/meSlice'
import Logo from '../../assets/img/logo.png'

const NavItem = ({ label, to, icon: Icon }: NavigationItem) => {
  const useStyles = createStyles(theme => ({
    item: {
      display: 'block',
      width: '100%',
      textTransform: 'capitalize',
      padding: 8,
      marginBottom: 5,
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      '&:last-child': {
        marginBottom: 0
      },
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
      }
    },
    itemActive: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[8] : theme.colors.blue[0],
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[8] : theme.colors.blue[0]
      }
    }
  }))
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <NavLink
      to={to}
      className={({ isActive }) => [classes.item, isActive ? classes.itemActive : null].filter(Boolean).join(' ')}
    >
      <Group>
        <ThemeIcon
          color="blue"
          variant="light"
        >
          <Icon size={16} />
        </ThemeIcon>
        <Text size="sm">{t(label)}</Text>
      </Group>
    </NavLink>
  )
}

const MainLayout = ({ children }: ILayoutProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const me = useAppSelector(state => state.me)
  const [opened, setOpened] = useState<boolean>(false)

  const useStyles = createStyles(theme => ({
    appMain: {
      background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
    },
    headerInline: {
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    },
    headerLogo: {
      width: 120,
      height: 27
    },
    localeChanger: {
      maxWidth: 70
    },
    userActions: {
      position: 'absolute',
      right: 15
    },
    contentScrollbar: {
      height: 'calc(100vh - 190px)',
      minHeight: '-webkit-fill-available'
    },
    content: {
      overflow: 'hidden'
    }
  }))
  const { classes } = useStyles()

  const handleLogout = async () => {
    dispatch(logout())
    dispatch(resetMe())
    return navigate('/auth/login')
  }

  return (
    <>
      {me.response && (
        <AppShell
          className={classes.appMain}
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          fixed
          header={
            <Header
              height={70}
              p="md"
            >
              <div className={classes.headerInline}>
                <MediaQuery
                  largerThan="sm"
                  styles={{ display: 'none' }}
                >
                  <Burger
                    opened={opened}
                    onClick={() => setOpened(o => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>
                <Image
                  src={Logo}
                  alt=""
                  className={classes.headerLogo}
                />
                <Group
                  position="right"
                  spacing="xs"
                  className={classes.userActions}
                >
                  <div className={classes.localeChanger}>
                    <LocaleChanger size="sm" />
                  </div>
                  <ThemeChanger size="lg" />
                  <Menu
                    placement="end"
                    withArrow
                    control={
                      <UnstyledButton>
                        <Group>
                          <Avatar
                            size={36}
                            color="blue"
                          >
                            {`${me.response.data.last_name.charAt(0)}${me.response.data.name.charAt(0)}`}
                          </Avatar>
                          <MediaQuery
                            smallerThan="sm"
                            styles={{ display: 'none' }}
                          >
                            <div>
                              <Text>{`${me.response.data.last_name}, ${me.response.data.name}`}</Text>
                              <Text
                                size="xs"
                                color="gray"
                              >
                                {me.response.data.email}
                              </Text>
                            </div>
                          </MediaQuery>
                        </Group>
                      </UnstyledButton>
                    }
                  >
                    <Menu.Item
                      component={Link}
                      to="/profile"
                      icon={<UserCircleIcon size={16} />}
                    >
                      {t('profile')}
                    </Menu.Item>
                    <Menu.Item
                      icon={<LogoutIcon size={16} />}
                      onClick={handleLogout}
                    >
                      {t('logout')}
                    </Menu.Item>
                  </Menu>
                </Group>
              </div>
            </Header>
          }
          navbar={
            <Navbar
              p="md"
              hiddenBreakpoint="sm"
              hidden={!opened}
              width={{ sm: 250, lg: 300 }}
            >
              <Navbar.Section
                grow
                component={ScrollArea}
                mx="-xs"
                px="xs"
              >
                {navigation.map((item, key) => (
                  <NavItem
                    {...item}
                    key={key}
                  />
                ))}
              </Navbar.Section>
            </Navbar>
          }
          footer={
            <Footer
              height={40}
              p="xs"
            >
              <Text
                size="sm"
                align="center"
              >
                &copy; {moment().format('Y')} Fleet Perfection.
              </Text>
            </Footer>
          }
          children={
            <>
              <Breadcrumb />
              <ScrollArea
                type="auto"
                className={classes.contentScrollbar}
              >
                <div className={classes.content}>{children}</div>
              </ScrollArea>
            </>
          }
        />
      )}
    </>
  )
}

export default MainLayout
