import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { IModelDetailParams } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchShowModelDetail, resetShowModelDetail } from '../../../store/features/modelDetails/showModelDetailSlice'
import {
  fetchUpdateModelDetail,
  resetUpdateModelDetail
} from '../../../store/features/modelDetails/updateModelDetailSlice'

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<IModelDetailParams | null>(null)

  const {
    isLoading: userIsLoading,
    response: userResponse,
    error: userError
  } = useAppSelector(state => state.showModelDetail)
  const { isLoading, response, error } = useAppSelector(state => state.updateModelDetail)

  useEffect(() => {
    if (userResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowModelDetail(id as string))
      })()
    }
  }, [userResponse, data, id, dispatch])

  const getSerializedData = (data: any) => {
    return {
      name: data.name,
      model_id: data.model.id
    }
  }

  useEffect(() => {
    if (userResponse !== null) {
      setData(getSerializedData(userResponse.data))

      if (data !== null) {
        dispatch(resetShowModelDetail())
      }
    }
  }, [userResponse, data, dispatch])

  useEffect(() => {
    if (userError !== null) {
      if (userError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowModelDetail())
          await dispatch(fetchShowModelDetail(id))
        })()
      } else if (userError.response.status === 404) {
        dispatch(resetShowModelDetail())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(userError.data.message)
        })

        navigate('/model-details')
      } else {
        dispatch(resetShowModelDetail())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/model-details')
      }
    }
  }, [userError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdateModelDetail())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdateModelDetail())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (params: IModelDetailParams) => {
    await dispatch(fetchUpdateModelDetail({ id: id as string, params }))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={userIsLoading || isLoading || !data} />
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-modelDetail-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/model-details" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-modelDetail-form" />
              <ResetButton form="edit-modelDetail-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
