import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AppStorage from "./storage";
import { en, tr } from "../locales";
import { app } from "../config";

if (AppStorage.getLocale() === null) {
  AppStorage.setLocale(app.DEFAULT_LOCALE);
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      tr: {
        translation: tr,
      },
    },
    lng: AppStorage.getLocale(),
    fallbackLng: app.DEFAULT_LOCALE,
    debug: app.ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
  })
  .then(_ => {
  });

export default i18n;
