import { AxiosError, AxiosRequestConfig } from 'axios'
import AbstractHttpClient from './AbstractHttpClient'
import { ILoginParams, IPasswordParams, IUpdatePasswordParams } from '../../store/types'
import { app } from '../../config'
import AppStorage from '../storage'
import history from '../history'

export default class AuthApiClient extends AbstractHttpClient {
  /**
   * @private classInstance
   */
  private static classInstance?: AuthApiClient

  /**
   * @private constructor
   */
  private constructor() {
    super(app.API_URL.replace(/^\/|\/$/g, '').concat('/auth'))

    this._initializeResponseInterceptor()
  }

  /**
   * @param config
   */
  private _setAuthorizationWithRefreshToken = (config: AxiosRequestConfig) => {
    const refreshToken = AppStorage.getRefreshToken()
    if (refreshToken) {
      config.headers = config.headers ?? {}
      config.headers.Authorization = `Bearer ${refreshToken}`
    }

    return config
  }

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
  }

  /**
   * @protected _handleError
   * @param error
   */
  protected _handleError = (error: AxiosError) => {
    const statusCode = Number(error.response?.status)
    const originalRequest = error.config

    originalRequest.headers = originalRequest.headers ?? {}

    if (originalRequest.headers.Authorization && 401 === statusCode) {
      this._removeTokensFromStorage()
      history.push('/auth/login')
    }

    return Promise.reject(error)
  }

  /**
   * @public getInstance
   */
  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new this()
    }

    return this.classInstance
  }

  /**
   * User login.
   */
  public login = async (params: ILoginParams) => {
    return await this.instance.post('/login', params.body)
  }

  /**
   * Refresh token.
   */
  public refresh = async () => {
    this.instance.interceptors.request.use(this._setAuthorizationWithRefreshToken)

    return await this.instance.patch('/refresh')
  }

  /**
   * Password.
   */
  public password = async (params: IPasswordParams) => await this.instance.post('/password', params)

  /**
   * Update password.
   */
  public updatePassword = async (token: string, params: IUpdatePasswordParams) => {
    return await this.instance.patch(`/password/${token}`, params)
  }
}
