import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {useTranslation} from "react-i18next";
import {Grid, TextInput, Select, SelectItem, MultiSelect} from "@mantine/core";
import {useForm, yupResolver} from "@mantine/form";
import * as Yup from "yup";
import {IFormProps, IFormRef} from "../../../types";
import {useAppSelector, useAppDispatch} from "../../../store";
import {fetchListCities} from "../../../store/features/shared/listCitiesSlice";
import {fetchListCompanies} from "../../../store/features/companies/listCompanySlice";

const Form = forwardRef(
  ({id, initialValues, onSubmit}: IFormProps, ref: Ref<IFormRef>) => {
    const {t} = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();
    const {response: companyResponse} =
      useAppSelector((state) => state.listCompany);
    const [companyOptions, setCompanyOptions] =
      useState<SelectItem[]>([]);

    const schema = Yup.object().shape({
      name: Yup.string().required(t("validation.required")),
    });

    const form = useForm({
      schema: yupResolver(schema),
      initialValues,
    });

    useEffect(() => {
      if (initialValues.state) {
        (async () => {
          await dispatch(fetchListCities(initialValues.state));
        })();
      }
    }, [initialValues, dispatch]);

    const handleReset = () => {
      form.reset();
    };

    useImperativeHandle(ref, () => ({
      reset: () => {
        handleReset();
      },
      setErrors: (errors) => {
        errors.forEach((error) => {
          form.setFieldError(error.key, error.message);
        });
      },
    }));

    useEffect(() => {
      if (companyResponse === null) {
        (async () => {
          await dispatch(
            fetchListCompanies({
              query: {
                page: 1,
                per: 1000,
              },
            })
          );
        })();
      } else {
        setCompanyOptions(
          companyResponse.data.items.map((company: any) => ({
            value: company.id,
            label: company.name,
          }))
        );
      }
    }, [companyResponse, dispatch]);

    const handleCompanyChange = async (value: string[] | null) => {
      form.setFieldValue("companies", value);
    };

    return (
      <form
        ref={formRef}
        id={id}
        onSubmit={form.onSubmit((data) => {
          onSubmit(schema.cast(data));
        })}
        onReset={handleReset}
        noValidate
      >
        <Grid>
          <Grid.Col sm={12} md={6} lg={4} xl={3}>
            <TextInput
              label={t("pages.package.name")}
              placeholder={t("name")}
              required
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={4} xl={3}>
            <Select
              data={[
                {value: "active", label: t("statuses.active")},
                {value: "inactive", label: t("statuses.inactive")}
              ]}
              label={t("status")}
              placeholder={t("status")}
              clearable
              required
              {...form.getInputProps("status")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={4} xl={3}>
            <MultiSelect
              label={t("companies")}
              placeholder={t("companies")}
              data={companyOptions}
              nothingFound={t("errors.no_options")}
              searchable
              clearable
              required
              {...form.getInputProps("companies")}
              //write ts ignore next line
              // @ts-ignore
              onChange={value => handleCompanyChange(value)}
            />
          </Grid.Col>
        </Grid>
      </form>
    );
  }
);

export default Form;
