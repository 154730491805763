import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextInput, Select, SelectItem } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import * as Yup from 'yup'
import { IFormProps, IFormRef } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { fetchListModels } from '../../../store/features/models/listModelSlice'

const Form = forwardRef(({ id, initialValues, onSubmit }: IFormProps, ref: Ref<IFormRef>) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [modelsOptions, setModelsOptions] = useState<SelectItem[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)
  const { isLoading: modelsIsLoading, response: modelsResponse } = useAppSelector(state => state.listModel)
  const schema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    model_id: Yup.string().required(t('validation.required'))
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  useEffect(() => {
    if (modelsResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListModels({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setModelsOptions(
        modelsResponse.data.items.map((model: any) => ({
          value: model.id,
          label: `${model.name}`
        }))
      )
    }
  }, [modelsResponse, dispatch])

  useEffect(() => {
    if (!modelsIsLoading && !initialized) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [initialized, form, initialValues, modelsIsLoading])

  const handleReset = () => {
    form.reset()
    setInitialized(false)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => onSubmit(schema.cast(data)))}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label="Model"
            placeholder="Model"
            data={modelsOptions}
            disabled={modelsIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('model_id')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('name')}
            placeholder=""
            required
            {...form.getInputProps('name')}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
