import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { ICompanyResponse, ICompanyState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchShowCompany = createAsyncThunk(
  'listCompanies/fetchShowCompany',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getCompany(id)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: ICompanyState = {
  isLoading: false,
  response: null,
  error: null
}

const showCompanySlice = createSlice({
  name: 'Companies/show',
  initialState,
  reducers: {
    resetShowCompany: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchShowCompany.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchShowCompany.fulfilled, (state, action: PayloadAction<ICompanyResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchShowCompany.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default showCompanySlice.reducer
export const { resetShowCompany } = showCompanySlice.actions
