import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IEmployeeParams, IEmployeeResponse, IEmployeeState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchCreateEmployee = createAsyncThunk(
  'listEmployees/fetchCreateEmployee',
  async (params: IEmployeeParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.createEmployee(params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IEmployeeState = {
  isLoading: false,
  response: null,
  error: null
}

const createEmployeeSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetCreateEmployee: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCreateEmployee.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchCreateEmployee.fulfilled, (state, action: PayloadAction<IEmployeeResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchCreateEmployee.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default createEmployeeSlice.reducer
export const { resetCreateEmployee } = createEmployeeSlice.actions
