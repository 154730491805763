import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IFinancingCompaniesParams, IFinancingCompaniesResponse, IFinancingCompaniesState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListFinancingCompanies = createAsyncThunk(
  'listFinancingCompanies/fetchListFinancingCompanies',
  async ({ query }: IFinancingCompaniesParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.financingCompanies(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IFinancingCompaniesState = {
  isLoading: false,
  response: null,
  error: null
}

const listFinancingCompaniesSlice = createSlice({
  name: 'financingCompanies/list',
  initialState,
  reducers: {
    resetListFinancingCompnaies: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListFinancingCompanies.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(
      fetchListFinancingCompanies.fulfilled,
      (state, action: PayloadAction<IFinancingCompaniesResponse>) => {
        state.isLoading = false
        state.response = action.payload
        state.error = null
      }
    )

    builder.addCase(fetchListFinancingCompanies.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listFinancingCompaniesSlice.reducer
export const { resetListFinancingCompnaies } = listFinancingCompaniesSlice.actions
