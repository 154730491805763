import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Button, Grid, Group, LoadingOverlay, MediaQuery, Pagination, Select, Table, TextInput } from '@mantine/core'
import { Search as SearchIcon, SquarePlus as SquarePlusIcon } from 'tabler-icons-react'
import { MainLayout } from '../../layouts'
import { useAppDispatch, useAppSelector } from '../../../store'
import { ICompaniesQueryParams } from '../../../store/types'
import { DEFAULT_PER_PAGE, SELECT_PER_PAGE_OPTIONS } from '../../../types'
import { fetchListCompanies } from '../../../store/features/companies/listCompanySlice'
import { fetchDeleteCompany, resetDeleteCompany } from '../../../store/features/companies/deleteCompanySlice'
import { ContentBox, ContentNotFound, ItemActionsMenu } from '../../partials'

const List = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [queryParams, setQueryParams] = useState<ICompaniesQueryParams>({
    page: 1,
    per: DEFAULT_PER_PAGE,
    ...qs.parse(search)
  })

  const { isLoading, response, error } = useAppSelector(state => state.listCompany)
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError
  } = useAppSelector(state => state.deleteCompany)

  const filterForm = useForm({
    initialValues: {
      q: queryParams.q || '',
      per: queryParams.per
    }
  })

  const handleFilterSubmit = async (data: ICompaniesQueryParams) => {
    setQueryParams({ ...queryParams, ...data, page: 1 })
    navigate(`/companies?${qs.stringify(data)}`, { replace: true })
  }

  useEffect(() => {
    dispatch(fetchListCompanies({ query: queryParams }))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (error !== null && error.response.status === 401) {
      dispatch(fetchListCompanies({ query: queryParams }))
    }
  }, [error, dispatch, queryParams])

  const handleChangePage = (page: number) => {
    if (page !== Number(queryParams.page)) {
      setQueryParams({ ...queryParams, page })
      navigate(`/companies?${qs.stringify({ ...queryParams, page })}`, { replace: true })
    }
  }

  const handleDelete = async (id: string) => {
    dispatch(fetchDeleteCompany(id))
  }

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetDeleteCompany())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted')
      })

      setQueryParams({ ...queryParams, page: 1 })
      navigate(`/companies?${qs.stringify({ ...queryParams, page: 1 })}`, { replace: true })
    }
  }, [deleteResponse, queryParams, t, dispatch, navigate])

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetDeleteCompany())

      showNotification({
        color: 'red',
        title: t('successful'),
        message: deleteError.response ? deleteError.response.message : t('errors.unknown')
      })
    }
  }, [deleteError, t, dispatch])

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading} />
      {response && (
        <MediaQuery
          largerThan="md"
          styles={{ flexDirection: 'row-reverse' }}
        >
          <Grid gutter="sm">
            <Grid.Col
              lg={3}
              md={4}
              sm={12}
            >
              <ContentBox title={t('filter')}>
                <form onSubmit={filterForm.onSubmit(handleFilterSubmit)}>
                  <TextInput
                    placeholder={t('keywords')}
                    label={t('keywords')}
                    mb="xs"
                    {...filterForm.getInputProps('q')}
                  />
                  <Select
                    data={SELECT_PER_PAGE_OPTIONS}
                    label={t('per_page')}
                    placeholder={t('select')}
                    mb="xs"
                    {...filterForm.getInputProps('per')}
                  />
                  <Button
                    type="submit"
                    leftIcon={<SearchIcon size={16} />}
                    variant="outline"
                    color="cyan"
                    fullWidth
                  >
                    {t('filter')}
                  </Button>
                </form>
              </ContentBox>
            </Grid.Col>
            <Grid.Col
              lg={9}
              md={8}
              sm={12}
            >
              <ContentBox
                title={t('companies')}
                subtitle={`${t('total')}: ${response.data.count}`}
                withoutSpacing
              >
                {response.data.items.length > 0 ? (
                  <div className="table-responsive">
                    <Table
                      striped
                      highlightOnHover
                    >
                      <thead>
                        <tr>
                          <th>{t('pages.package.name')}</th>
                          <th>{t('email')}</th>
                          <th>{t('person')}</th>
                          <th>{t('phone')}</th>
                          <th>{t('package')}</th>
                          <th className="text-right">{t('monthly_average_fleet_size')}</th>
                          <th className="text-center">{t('actions')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.data.items.map((company) => (
                          <tr key={company.id}>
                            <td>{company.name}</td>
                            <td>{company.email ? company.email : '-'}</td>
                            <td>{company.person ? company.person : '-'}</td>
                            <td>{company.phone ? company.phone : '-'}</td>
                            <td>{company.package.name}</td>
                            <td className="text-right">{company.monthly_average_fleet_size}</td>
                            <td className="text-center">
                              <ItemActionsMenu
                                show={`/companies/${company.id}`}
                                edit={`/companies/${company.id}/edit`}
                                onDelete={() => handleDelete(company.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <ContentNotFound />
                )}
              </ContentBox>
              <ContentBox>
                <Grid>
                  <Grid.Col span={8}>
                    {response.data.pagination.last > 1 && (
                      <Pagination
                        page={Number(queryParams.page)}
                        onChange={handleChangePage}
                        total={response.data.pagination.last}
                      />
                    )}
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Group position="right">
                      <Button
                        leftIcon={<SquarePlusIcon size={16} />}
                        component={Link}
                        to="/companies/create"
                        variant="outline"
                        color="green"
                      >
                        {t('content_actions.create')}
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </ContentBox>
            </Grid.Col>
          </Grid>
        </MediaQuery>
      )}
    </MainLayout>
  )
}

export default List
