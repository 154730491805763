import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {createStyles, Modal, Table} from "@mantine/core";
import {ExternalAccountType} from "../../../store/types";
import {DATETIME_FORMAT} from "../../../types";
import Moment from "react-moment";

type PropsType = {
  account: ExternalAccountType | null;
  onClose: () => void;
}

const useStyles = createStyles(() => ({
  table: {
    '& td': {
      verticalAlign: 'top'
    }
  }
}));

const ExternalAccountModal = ({account, onClose}: PropsType) => {
  const {t} = useTranslation();
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState<string>(t('report_logs'));
  const {classes} = useStyles();

  useEffect(() => {
    if (account === null) {
      setOpened(false);
    } else {
      setOpened(true);
      setTitle(`${t('external_account')} | ${t(`external_account_types.${account.provider}`)}`)
    }
  }, [account, t]);

  const handleClose = () => {
    setOpened(false);
    onClose();
  }

  return (
    <Modal opened={opened}
           onClose={handleClose}
           title={title}
           size="lg"
           centered>
      <>
        {account !== null && (
          <>
            <Table highlightOnHover className={classes.table}>
              <tbody>
              <tr>
                <td style={{width: '30%'}}>
                  <strong>ID</strong>
                </td>
                <td style={{width: '70%'}}>{account.id}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('provider')}</strong>
                </td>
                <td>{t(`external_account_types.${account.provider}`)}</td>
              </tr>
              {account.key !== null && (
                <tr>
                  <td>
                    <strong>{t('key')}</strong>
                  </td>
                  <td>{account.key}</td>
                </tr>
              )}
              {account.token_type !== null && (
                <tr>
                  <td>
                    <strong>{t('token_type')}</strong>
                  </td>
                  <td>{account.token_type}</td>
                </tr>
              )}
              {account.access_token !== null && (
                <tr>
                  <td>
                    <strong>{t('access_token')}</strong>
                  </td>
                  <td>{account.access_token}</td>
                </tr>
              )}
              {account.refresh_token !== null && (
                <tr>
                  <td>
                    <strong>{t('refresh_token')}</strong>
                  </td>
                  <td>{account.refresh_token}</td>
                </tr>
              )}
              {account.expires_in !== null && (
                <tr>
                  <td>
                    <strong>{t('expires_in')}</strong>
                  </td>
                  <td>{account.expires_in}</td>
                </tr>
              )}
              {account.account_id !== null && (
                <tr>
                  <td>
                    <strong>{t('account_id')}</strong>
                  </td>
                  <td>{account.account_id}</td>
                </tr>
              )}
              {account.username !== null && (
                <tr>
                  <td>
                    <strong>{t('username')}</strong>
                  </td>
                  <td>{account.username}</td>
                </tr>
              )}
              {account.password !== null && (
                <tr>
                  <td>
                    <strong>{t('password')}</strong>
                  </td>
                  <td>{account.password}</td>
                </tr>
              )}
              {account.is_re_access_required !== null && (
                <tr>
                  <td>
                    <strong>{t('is_re_access_required')}</strong>
                  </td>
                  <td>{account.is_re_access_required ? t('yes') : t('no')}</td>
                </tr>
              )}
              <tr>
                <td>
                  <strong>{t('created_at')}</strong>
                </td>
                <td>
                  <Moment format={DATETIME_FORMAT}>{account.created_at}</Moment>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t('updated_at')}</strong>
                </td>
                <td>
                  <Moment format={DATETIME_FORMAT}>{account.updated_at}</Moment>
                </td>
              </tr>
              </tbody>
            </Table>
          </>
        )}
      </>
    </Modal>
  )
}

export default ExternalAccountModal
