import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Divider, Grid, PasswordInput, Switch, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { At as AtIcon, Key as KeyIcon } from "tabler-icons-react";
import { AuthLayout } from "../../layouts";
import { LocaleChanger, ThemeChanger } from "../../partials";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchLogin, resetLogin } from "../../../store/features/auth/loginSlice";
import { fetchMe } from "../../../store/features/userOperations/meSlice";
import Yup from "../../../utils/yup";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const login = useAppSelector(state => state.login);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const form = useForm({
    schema: yupResolver(Yup.object().shape({
      username: Yup.string()
        .required(t('validation.required', { prop: t('email') }))
        .email(t('validation.invalid', { prop: t('email') })),
      password: Yup.string()
        .required(t('validation.required', { prop: t('password') }))
        .min(4, t('validation.min', { prop: t('password'), length: 4 })),
      remember_me: Yup.boolean(),
    })),
    initialValues: {
      username: '',
      password: '',
      remember_me: false,
    },
  });

  useEffect(() => {
    if (login.error) {
      let notification = { color: 'red', title: t('errors.unknown'), message: t('please_try_again') };

      if (login.error.response.status > 400) {
        notification = { ...notification, title: t('error'), message: login.error.response.data.message };
      }

      dispatch(resetLogin());
      showNotification(notification);
    }
  }, [login, t, dispatch]);


  useEffect(() => {
    if (login.response) {
      setIsRedirecting(true);

      setTimeout(() => {
        dispatch(fetchMe());
        navigate('/');
      }, 2000);
    }
  }, [login, dispatch, navigate]);

  const handleSubmit = async (data: any) => {
    dispatch(fetchLogin({ body: data }));
  };

  return (
    <AuthLayout>
      <Grid>
        <Grid.Col span={ 8 }>
          <ThemeChanger/>
        </Grid.Col>
        <Grid.Col span={ 4 }>
          <LocaleChanger size="xs"/>
        </Grid.Col>
      </Grid>

      <Divider variant="dashed" size="xs" mt="sm" mb="sm"/>

      <form onSubmit={ form.onSubmit(handleSubmit) }>
        <TextInput
          type="email"
          label={ t('email') }
          placeholder="example@mail.com"
          icon={ <AtIcon size={ 16 }/> }
          autoFocus
          { ...form.getInputProps('username') }
        />
        <PasswordInput
          label={ t('password') }
          placeholder="p@ssw0rd"
          icon={ <KeyIcon size={ 16 }/> }
          mt="sm"
          { ...form.getInputProps('password') }
        />
        <Switch
          label={ t('remember_me') }
          mt="sm" { ...form.getInputProps('remember_me', { type: 'checkbox' }) }
        />

        <Divider variant="dashed" size="xs" mt="sm" mb="sm"/>

        <Button type="submit"
                variant="filled"
                mt="sm"
                loading={ login.isLoading || isRedirecting }
                uppercase
                fullWidth>{ t('login') }</Button>
      </form>
    </AuthLayout>
  );
};

export default Login;
