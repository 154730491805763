import { useTranslation } from "react-i18next";
import { Center } from "@mantine/core";

type PropsType = {
  message?: string;
}

const ContentNotFound = ({ message }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Center style={ { width: '100%', height: 200 } }>
      <p>{ message ?? t('errors.content_not_found') }</p>
    </Center>
  );
};

export default ContentNotFound;
