import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import AppStorage from '../storage'
import history from '../history'

declare module 'axios' {
  /**
   * @interface AxiosResponse
   */
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export default abstract class AbstractHttpClient {
  /**
   * @readonly instance
   * @protected instance
   */
  protected readonly instance: AxiosInstance

  /**
   * @param baseURL
   * @protected constructor
   */
  protected constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL
    })

    this._initializeResponseInterceptor()
  }

  /**
   * @private _initializeResponseInterceptor
   */
  protected _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
  }

  /**
   * @private _handleResponse
   * @param response
   */
  protected _handleResponse = (response: AxiosResponse) => {
    return response
  }

  /**
   * @protected _handleError
   * @param error
   */
  protected _handleError = (error: AxiosError) => {
    if (!error.response) {
    }

    return Promise.reject(error)
  }

  /**
   * Remove access token from storage.
   */
  protected _removeAccessTokenFromStorage = (): void => {
    AppStorage.removeAccessToken()
  }

  /**
   * Remove tokens from storage.
   */
  protected _removeTokensFromStorage = (): void => {
    AppStorage.removeTokens()
  }

  /**
   * @protected _handleError
   * @param error
   */
  protected _authenticatedHandleError = async (error: AxiosError) => {
    const statusCode = Number(error.response?.status)

    if (401 === statusCode) {
      if (AppStorage.getRefreshToken() !== null) {
      } else {
        this._removeAccessTokenFromStorage()
        history.push('/auth/login')
      }
    } else if (403 === statusCode) {
      this._removeTokensFromStorage()
      history.push('/auth/login')
    }

    return Promise.reject(error)
  }
}
