import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import {ICompanyVehicleGroupsParams, ICompanyVehicleGroupsResponse, ICompanyVehicleGroupsState} from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListCompanyVehicleGroups = createAsyncThunk(
    'listCompanyVehicleGroups/fetchListCompanyVehicleGroups',
    async ({query}: ICompanyVehicleGroupsParams, {rejectWithValue}) => {
        try {
            const response = await adminApiClient.getCompanyVehicleGroups(query)
            return response.data
        } catch (error: any) {
            if (!error.response) {
                throw error
            }

            return rejectWithValue(error)
        }
    }
)

const initialState: ICompanyVehicleGroupsState = {
    isLoading: false,
    response: null,
    error: null
}

const listCompanyVehicleGroupsSlice = createSlice({
    name: 'groups/list',
    initialState,
    reducers: {
        resetListCompanyVehicleGroups: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(fetchListCompanyVehicleGroups.pending, state => {
            state.isLoading = true
            state.response = null
            state.error = null
        })

        builder.addCase(fetchListCompanyVehicleGroups.fulfilled, (state, action: PayloadAction<ICompanyVehicleGroupsResponse>) => {
            state.isLoading = false
            state.response = action.payload
            state.error = null
        })

        builder.addCase(fetchListCompanyVehicleGroups.rejected, (state, action) => {
            state.isLoading = false
            state.response = null
            state.error = action.payload
        })
    }
})

export default listCompanyVehicleGroupsSlice.reducer
export const {resetListCompanyVehicleGroups} = listCompanyVehicleGroupsSlice.actions
