import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SharedApiClient from "../../../utils/http-clients/SharedApiClient";
import { IStatesResponse, IStatesState } from "../../types";

const sharedApiClient = SharedApiClient.getInstance();

export const fetchListCities = createAsyncThunk('shared/fetchListCities', async (
  stateId: string, { rejectWithValue }
) => {
  try {
    const response = await sharedApiClient.cities(stateId);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IStatesState = {
  isLoading: false,
  response: null,
  error: null,
};

const listCitiesSlice = createSlice({
  name: 'cities/list',
  initialState,
  reducers: {
    resetListCities: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchListCities.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchListCities.fulfilled, (state, action: PayloadAction<IStatesResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchListCities.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default listCitiesSlice.reducer;
export const { resetListCities } = listCitiesSlice.actions;
