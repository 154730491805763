import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import { IUserResponse, IUserState } from "../../types";

const adminApiClient = AdminApiClient.getInstance();

export const fetchShowUser = createAsyncThunk('listUsers/fetchShowUser', async (
  id: string, { rejectWithValue }
) => {
  try {
    const response = await adminApiClient.user(id);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IUserState = {
  isLoading: false,
  response: null,
  error: null,
};

const showUserSlice = createSlice({
  name: 'users/show',
  initialState,
  reducers: {
    resetShowUser: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchShowUser.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchShowUser.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchShowUser.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default showUserSlice.reducer;
export const { resetShowUser } = showUserSlice.actions;
