import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { ICompanyParams } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchCreateCompany, resetCreateCompany } from '../../../store/features/companies/createCompanySlice'

const Create = () => {
  const { t } = useTranslation()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()

  const { isLoading, response, error } = useAppSelector(state => state.createCompany)

  useEffect(() => {
    if (response !== null) {
      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('created')
      })

      dispatch(resetCreateCompany())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetCreateCompany())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (data: ICompanyParams) => {
    await dispatch(fetchCreateCompany(data))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading} />
      <ContentBox title={t('content_actions.create')}>
        <Form
          id="create-company-form"
          type={FormTypes.CREATE}
          ref={formRef}
          initialValues={{
            name: '',
            state: '',
            city: '',
            address: '',
            zip: '',
            person: '',
            phone: '',
            email: '',
            package: '',
            blackbook_query_fee: null,
          }}
          onSubmit={handleSubmit}
        />
      </ContentBox>
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/companies" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="create-company-form" />
              <ResetButton form="create-company-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Create
