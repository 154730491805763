import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { ILocationParams, ILocationResponse, ILocationState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateLocation = createAsyncThunk(
  'listLocations/fetchUpdateLocation',
  async ({ id, params }: { id: string; params: ILocationParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateLocation(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: ILocationState = {
  isLoading: false,
  response: null,
  error: null
}

const updateLocationSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateLocation: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateLocation.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateLocation.fulfilled, (state, action: PayloadAction<ILocationResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateLocation.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateLocationSlice.reducer
export const { resetUpdateLocation } = updateLocationSlice.actions
