import React from "react";
import { useTranslation } from "react-i18next";
import { ActionIcon, useMantineColorScheme } from "@mantine/core";
import { MantineSize } from "@mantine/styles";
import { MoonStars as MoonStarsIcon, Sun as SunIcon } from "tabler-icons-react";

type PropsType = {
  size?: MantineSize;
}

const ThemeChanger = ({ size = 'md' }: PropsType) => {
  const { t } = useTranslation();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  return (
    <ActionIcon
      variant="outline"
      size={ size }
      color={ dark ? 'yellow' : 'blue' }
      onClick={ () => toggleColorScheme() }
      title={ dark ? t('theme.light') : t('theme.dark') }
    >
      { dark ? <SunIcon size={ 24 }/> : <MoonStarsIcon size={ 24 }/> }
    </ActionIcon>
  );
};

export default ThemeChanger;
