import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@mantine/core";
import { MantineSize } from "@mantine/styles";
import { changeLocale } from "../../utils/mixins";

type PropsType = {
  size?: MantineSize;
}

type LocaleOptionType = {
  value: string;
  label: string;
};

const LocaleChanger = ({ size = 'sm' }: PropsType) => {
  const { i18n } = useTranslation();
  const [locales, setLocales] = useState<Array<LocaleOptionType>>([]);

  useEffect(() => {
    if (i18n.options.resources) {
      setLocales(Object.keys(i18n.options.resources).map(lang => ({
        value: lang,
        label: lang.toUpperCase(),
      })));
    }
  }, [i18n.options.resources]);

  return (
    <Select
      data={ locales }
      value={ i18n.language } onChange={ (locale: string) => changeLocale(locale) }
      size={ size }/>
  );
};

export default LocaleChanger;
