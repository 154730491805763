import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { ILocationParams, LocationType } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchShowLocation, resetShowLocation } from '../../../store/features/locations/showLocationSlice'
import { fetchUpdateLocation, resetUpdateLocation } from '../../../store/features/locations/updateLocationSlice'

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<ILocationParams | null>(null)

  const {
    isLoading: userIsLoading,
    response: userResponse,
    error: userError
  } = useAppSelector(state => state.showLocation)
  const { isLoading, response, error } = useAppSelector(state => state.updateLocation)

  useEffect(() => {
    if (userResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowLocation(id as string))
      })()
    }
  }, [userResponse, data, id, dispatch])

  const getSerializedData = (data: LocationType) => {
    return {
      name: data.name,
      email: data.email,
      password: '',
      password_confirmation: '',
      phone: data.phone,
      code: data.code,
      region: data.region,
      finance_length: data.finance_length,
      pickup_location_number: data.pickup_location_number,
      zubie_location_name: data.zubie_location_name,
      monthly_depreciation_assumption: data.monthly_depreciation_assumption,
      monthly_mileage_assumption: data.monthly_mileage_assumption,
      person: data.person,
      company: data.company
    }
  }

  useEffect(() => {
    if (userResponse !== null) {
      setData(getSerializedData(userResponse.data))

      if (data !== null) {
        dispatch(resetShowLocation())
      }
    }
  }, [userResponse, data, dispatch])

  useEffect(() => {
    if (userError !== null) {
      if (userError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowLocation())
          await dispatch(fetchShowLocation(id))
        })()
      } else if (userError.response.status === 404) {
        dispatch(resetShowLocation())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(userError.data.message)
        })

        navigate('/locations')
      } else {
        dispatch(resetShowLocation())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/locations')
      }
    }
  }, [userError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdateLocation())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdateLocation())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (params: ILocationParams) => {
    await dispatch(fetchUpdateLocation({ id: id as string, params }))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={userIsLoading || isLoading || !data} />
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-location-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/locations" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-location-form" />
              <ResetButton form="edit-location-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
