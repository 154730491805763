import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IClassParams, IClassResponse, IClassState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateClass = createAsyncThunk(
  'listClasses/fetchUpdateClass',
  async ({ id, params }: { id: string; params: IClassParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateClass(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IClassState = {
  isLoading: false,
  response: null,
  error: null
}

const updateClassSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateClass: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateClass.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateClass.fulfilled, (state, action: PayloadAction<IClassResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateClass.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateClassSlice.reducer
export const { resetUpdateClass } = updateClassSlice.actions
