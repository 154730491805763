import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AuthApiClient from "../../../utils/http-clients/AuthApiClient";
import { ILoginParams, ILoginState, ILoginTokenResponse } from "../../types";
import AppStorage from "../../../utils/storage";

const authApiClient = AuthApiClient.getInstance();

export const fetchLogin = createAsyncThunk('auth/login/fetchLogin', async (
  params: ILoginParams, { rejectWithValue }
) => {
  try {
    const response = await authApiClient.login(params);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: ILoginState = {
  isLoading: false,
  response: null,
  error: null,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    resetLogin: () => initialState,
    logout: () => {
      AppStorage.removeTokens();
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLogin.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchLogin.fulfilled, (state, action: PayloadAction<ILoginTokenResponse>) => {
      AppStorage.setTokens(action.payload.data.token, action.payload.data.refresh_token);

      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default loginSlice.reducer;
export const { resetLogin, logout } = loginSlice.actions;
