import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import { ICompanyResponse, ICompanyState } from "../../types";

const adminApiClient = AdminApiClient.getInstance();

export const fetchUpdateROC = createAsyncThunk(
  "listCompanies/fetchUpdateROC",
  async ({ id, params }: { id: string; params: any }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateROC(id, params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

const initialState: ICompanyState = {
  isLoading: false,
  response: null,
  error: null,
};

const updateROCSlice = createSlice({
  name: "states/show",
  initialState,
  reducers: {
    resetROC: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateROC.pending, (state) => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(
      fetchUpdateROC.fulfilled,
      (state, action: PayloadAction<ICompanyResponse>) => {
        state.isLoading = false;
        state.response = action.payload;
        state.error = null;
      }
    );

    builder.addCase(fetchUpdateROC.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default updateROCSlice.reducer;
export const { resetROC } = updateROCSlice.actions;
