import { useTranslation } from "react-i18next";
import { Button } from "@mantine/core";
import { SquareX as SquareXIcon } from "tabler-icons-react";

type PropsType = {
  form: string;
}

const ResetButton = ({ form }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Button type="reset"
            leftIcon={ <SquareXIcon size={ 18 }/> }
            color="yellow"
            form={ form }>{ t('content_actions.cancel') }</Button>
  );
}

export default ResetButton;
