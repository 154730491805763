import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import {IStopContinuallyInvoiceState, IResponse} from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchStopContinuallyInvoice = createAsyncThunk(
  'invoice/fetchStopContinuallyInvoice',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.stopContinuallyInvoice(id)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IStopContinuallyInvoiceState = {
  isLoading: false,
  response: null,
  error: null
}

const stopContinuallyInvoice = createSlice({
  name: 'states/stop',
  initialState,
  reducers: {
    resetStopContinuallyInvoice: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStopContinuallyInvoice.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchStopContinuallyInvoice.fulfilled, (state, action: PayloadAction<IResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchStopContinuallyInvoice.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default stopContinuallyInvoice.reducer
export const { resetStopContinuallyInvoice } = stopContinuallyInvoice.actions
