import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Grid, Group, LoadingOverlay} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {MainLayout} from "../../layouts";
import {
  ContentBox,
  BackListButton,
  SaveButton,
  ResetButton,
} from "../../partials";
import {FormTypes} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../store";
import {ICompanyParams} from "../../../store/types";
import {IFormRef} from "../../../types";
import {getFormattedValidationErrors} from "../../../utils/mixins";
import Form from "./Form";
import {
  fetchUpdateROC,
  resetROC,
} from "../../../store/features/revenueOutsourceCompanies/updateROCSlice";
import {
  fetchShowROC,
  resetShowROC,
} from "../../../store/features/revenueOutsourceCompanies/showROCSlice";

const Edit = () => {
  const {t} = useTranslation();
  const {id} = useParams<{ id: string }>();
  const navigate = useNavigate();
  const formRef = useRef<IFormRef>(null);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>(null);

  const {
    isLoading: showCompanyIsLoading,
    response: showCompanyResponse,
    error: showCompanyError,
  } = useAppSelector((state) => state.showROC);
  const {isLoading, response, error} = useAppSelector(
    (state) => state.updateROC
  );

  useEffect(() => {
    if (showCompanyResponse === null && data === null) {
      (async () => {
        await dispatch(fetchShowROC(id as string));
      })();
    }
  }, [showCompanyResponse, data, id, dispatch]);

  const getSerializedData = (data: any) => {
    return {
      name: data.name,
      status: data.status,
      companies: data.companies.map((item: any) => item.id),
    };
  };

  useEffect(() => {
    if (showCompanyResponse !== null) {
      setData(getSerializedData(showCompanyResponse.data));

      if (data !== null) {
        dispatch(resetShowROC());
      }
    }
  }, [showCompanyResponse, data, dispatch]);

  useEffect(() => {
    if (showCompanyError !== null) {
      if (showCompanyError.response.status === 401 && id) {
        (async () => {
          dispatch(resetShowROC());
          await dispatch(fetchShowROC(id));
        })();
      } else if (showCompanyError.response.status === 404) {
        dispatch(resetShowROC());
        showNotification({
          color: "red",
          title: t("error"),
          message: t(showCompanyError.data.message),
        });

        navigate("/companies");
      } else {
        dispatch(resetShowROC());
        showNotification({
          color: "red",
          title: t("error"),
          message: t("errors.unknown"),
        });

        navigate("/companies");
      }
    }
  }, [showCompanyError, id, dispatch, t, navigate]);

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data));

      showNotification({
        color: "green",
        title: t("successful"),
        message: t("updated"),
      });

      dispatch(resetROC());
    }
  }, [response, t, dispatch]);

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error));
      } else {
        showNotification({
          color: "red",
          title: t("unsuccessful"),
          message: error.response.data
            ? error.response.data.message
            : t("errors.unknown"),
        });
      }
    }
  }, [error, t]);

  useEffect(() => {
    if (response !== null) {
      dispatch(resetROC());
      formRef.current!.reset();
    }
  }, [response, dispatch]);

  const handleSubmit = async (params: ICompanyParams) => {
    await dispatch(fetchUpdateROC({id: id as string, params}));
  };

  return (
    <MainLayout>
      <LoadingOverlay visible={showCompanyIsLoading || isLoading || !data}/>
      {data && (
        <ContentBox title={t("content_actions.create")}>
          <Form
            id="edit-company-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/revenue-outsource-companies"/>
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-company-form"/>
              <ResetButton form="edit-company-form"/>
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  );
};

export default Edit;
