import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { IFinancingCompanyParams } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import {
  fetchCreateFinancingCompany,
  resetCreateFinancingCompany
} from '../../../store/features/financingCompanies/createFinancingCompanySlice'

const Create = () => {
  const { t } = useTranslation()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()

  const { isLoading, response, error } = useAppSelector(state => state.createFinancingCompany)

  useEffect(() => {
    if (response !== null) {
      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('created')
      })

      dispatch(resetCreateFinancingCompany())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetCreateFinancingCompany())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (data: IFinancingCompanyParams) => {
    await dispatch(fetchCreateFinancingCompany(data))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading} />
      <ContentBox title={t('content_actions.create')}>
        <Form
          id="create-financingCompanies-form"
          type={FormTypes.CREATE}
          ref={formRef}
          initialValues={{
            name: ''
          }}
          onSubmit={handleSubmit}
        />
      </ContentBox>
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/financing-companies" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="create-financingCompanies-form" />
              <ResetButton form="create-financingCompanies-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Create
