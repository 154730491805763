import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IModelDetailResponse, IModelDetailState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchShowModelDetail = createAsyncThunk(
  'listModelDetails/fetchShowModelDetail',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getModelDetail(id)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IModelDetailState = {
  isLoading: false,
  response: null,
  error: null
}

const showModelDetailSlice = createSlice({
  name: 'modeldetails/show',
  initialState,
  reducers: {
    resetShowModelDetail: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchShowModelDetail.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchShowModelDetail.fulfilled, (state, action: PayloadAction<IModelDetailResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchShowModelDetail.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default showModelDetailSlice.reducer
export const { resetShowModelDetail } = showModelDetailSlice.actions
