import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IFinancingCompanyParams, IFinancingCompanyResponse, IFinancingCompanyState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateFinancingCompany = createAsyncThunk(
  'listFinancingCompanies/fetchUpdateFinancingCompany',
  async ({ id, params }: { id: string; params: IFinancingCompanyParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateFinancingCompany(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IFinancingCompanyState = {
  isLoading: false,
  response: null,
  error: null
}

const updateFinancingCompanySlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateFinancyCompany: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateFinancingCompany.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(
      fetchUpdateFinancingCompany.fulfilled,
      (state, action: PayloadAction<IFinancingCompanyResponse>) => {
        state.isLoading = false
        state.response = action.payload
        state.error = null
      }
    )

    builder.addCase(fetchUpdateFinancingCompany.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateFinancingCompanySlice.reducer
export const { resetUpdateFinancyCompany } = updateFinancingCompanySlice.actions
