import { forwardRef, Ref, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import * as Yup from 'yup'
import { IFormProps, IFormRef } from '../../../types'

const Form = forwardRef(({ id, type, initialValues, onSubmit }: IFormProps, ref: Ref<IFormRef>) => {
  const { t } = useTranslation()
  const formRef = useRef<HTMLFormElement>(null)

  const schema = Yup.object().shape({
    name: Yup.string().required(t('validation.required'))
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  const handleReset = () => {
    form.reset()
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => onSubmit(schema.cast(data)))}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('name')}
            placeholder="John"
            required
            {...form.getInputProps('name')}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
