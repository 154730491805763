import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {ActionIcon, Divider, Menu, Text} from '@mantine/core'
import {useModals} from '@mantine/modals'
import {MantineSize} from '@mantine/styles'
import {
  Eye as EyeIcon,
  Pencil as PencilIcon,
  Tool as ToolIcon,
  Trash as TrashIcon,
  User as UserIcon,
  Article as ArticleIcon,
  Send as SendIcon
} from 'tabler-icons-react'

type PropsType = {
  show?: string
  edit?: string
  onDelete?: () => void
  impersonate?: () => void
  showReportLogs?: () => void
  sendReports?: () => void
  size?: MantineSize
}

const UserItemActionsMenu = ({
                               show,
                               edit,
                               onDelete,
                               impersonate,
                               showReportLogs,
                               sendReports,
                               size = 'md'
                             }: PropsType) => {
  const {t} = useTranslation()
  const modals = useModals()

  const deleteConfirmModal = () =>
    modals.openConfirmModal({
      title: t('confirmation_required'),
      centered: true,
      children: <Text size="sm">{t('delete_confirmation_message')}</Text>,
      labels: {confirm: t('yes'), cancel: t('no')},
      confirmProps: {color: 'red'},
      onCancel: () => {
      },
      onConfirm: onDelete
    })

  return (
    <Menu
      placement="end"
      withArrow
      control={
        <ActionIcon
          variant="outline"
          color="blue"
          size={size}
        >
          <ToolIcon size={16}/>
        </ActionIcon>
      }
    >
      {show && (
        <Menu.Item
          icon={<EyeIcon size={16}/>}
          component={Link}
          to={show}
        >
          {t('content_actions.show')}
        </Menu.Item>
      )}
      {edit && (
        <Menu.Item
          icon={<PencilIcon size={16}/>}
          component={Link}
          to={edit}
        >
          {t('content_actions.edit')}
        </Menu.Item>
      )}
      {impersonate && (
        <Menu.Item
          icon={<UserIcon size={16}/>}
          onClick={impersonate}
        >
          {t('content_actions.impersonate')}
        </Menu.Item>
      )}
      {showReportLogs && (
        <Menu.Item
          icon={<ArticleIcon size={16}/>}
          onClick={showReportLogs}
        >
          {t('content_actions.show_report_logs')}
        </Menu.Item>
      )}
      {sendReports && (
        <Menu.Item
          icon={<SendIcon size={16}/>}
          onClick={sendReports}
        >
          {t('content_actions.send_reports')}
        </Menu.Item>
      )}
      {onDelete && (
        <>
          <Divider my="sm"/>
          <Menu.Item
            icon={<TrashIcon size={16}/>}
            color="red"
            onClick={() => deleteConfirmModal()}
          >
            {t('content_actions.delete')}
          </Menu.Item>
        </>
      )}
    </Menu>
  )
}

export default UserItemActionsMenu
