import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Group, LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { ContentBox, BackListButton, SaveButton, ResetButton } from '../../partials'
import { FormTypes } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { IPackageParams, PackageType } from '../../../store/types'
import { IFormRef } from '../../../types'
import { getFormattedValidationErrors } from '../../../utils/mixins'
import Form from './Form'
import { fetchShowPackage, resetShowPackage } from '../../../store/features/packages/showPackageSlice'
import { fetchUpdatePackage, resetUpdatePackage } from '../../../store/features/packages/updatePackageSlice'

const Edit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const formRef = useRef<IFormRef>(null)
  const dispatch = useAppDispatch()
  const [data, setData] = useState<IPackageParams | null>(null)

  const {
    isLoading: packageIsLoading,
    response: packageResponse,
    error: packageError
  } = useAppSelector(state => state.showPackage)
  const { isLoading, response, error } = useAppSelector(state => state.updatePackage)

  useEffect(() => {
    if (packageResponse === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowPackage(id as string))
      })()
    }
  }, [packageResponse, data, id, dispatch])

  const getSerializedData = (data: PackageType) => {
    return {
      is_demo: data.is_demo,
      name: data.name,
      description: data.description,
      fleet_size: data.fleet_size,
      manheim_query_limit: data.manheim_query_limit,
      annual_fee: data.annual_fee,
      monthly_fee: data.monthly_fee
    }
  }

  useEffect(() => {
    if (packageResponse !== null) {
      setData(getSerializedData(packageResponse.data))

      if (data !== null) {
        dispatch(resetShowPackage())
      }
    }
  }, [packageResponse, data, dispatch])

  useEffect(() => {
    if (packageError !== null) {
      if (packageError.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowPackage())
          await dispatch(fetchShowPackage(id))
        })()
      } else if (packageError.response.status === 404) {
        dispatch(resetShowPackage())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(packageError.data.message)
        })

        navigate('/packages')
      } else {
        dispatch(resetShowPackage())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/packages')
      }
    }
  }, [packageError, id, dispatch, t, navigate])

  useEffect(() => {
    if (response !== null) {
      setData(getSerializedData(response.data))

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('updated')
      })

      dispatch(resetUpdatePackage())
    }
  }, [response, t, dispatch])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 422) {
        formRef.current!.setErrors(getFormattedValidationErrors(error))
      } else {
        showNotification({
          color: 'red',
          title: t('unsuccessful'),
          message: error.response.data ? error.response.data.message : t('errors.unknown')
        })
      }
    }
  }, [error, t])

  useEffect(() => {
    if (response !== null) {
      dispatch(resetUpdatePackage())
      formRef.current!.reset()
    }
  }, [response, dispatch])

  const handleSubmit = async (params: IPackageParams) => {
    console.log('params', params)
    await dispatch(fetchUpdatePackage({ id: id as string, params }))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={packageIsLoading || isLoading || !data} />
      {data && (
        <ContentBox title={t('content_actions.create')}>
          <Form
            id="edit-package-form"
            type={FormTypes.EDIT}
            ref={formRef}
            initialValues={data}
            onSubmit={handleSubmit}
          />
        </ContentBox>
      )}
      <ContentBox>
        <Grid>
          <Grid.Col span={6}>
            <BackListButton to="/packages" />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              <SaveButton form="edit-package-form" />
              <ResetButton form="edit-package-form" />
            </Group>
          </Grid.Col>
        </Grid>
      </ContentBox>
    </MainLayout>
  )
}

export default Edit
