import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IModelParams, IModelResponse, IModelState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateModel = createAsyncThunk(
  'listModels/fetchUpdateModel',
  async ({ id, params }: { id: string; params: IModelParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateModel(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IModelState = {
  isLoading: false,
  response: null,
  error: null
}

const updateModelSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateModel: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateModel.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateModel.fulfilled, (state, action: PayloadAction<IModelResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateModel.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateModelSlice.reducer
export const { resetUpdateModel } = updateModelSlice.actions
