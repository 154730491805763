import ILayoutProps from "./ILayout";
import { Box, Container, createStyles, Image, Stack } from "@mantine/core";
import Logo from "../../assets/img/logo.png";

const ErrorLayout = ({ children }: ILayoutProps) => {
  const useStyles = createStyles((theme) => ({
    stack: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      height: '100%',
    },
    box: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[0],
      padding: theme.spacing.md,
      width: '100%',
      maxWidth: 360,
      boxShadow: theme.shadows.sm,
      borderRadius: theme.radius.sm,
    },
    inline: {
      marginTop: theme.spacing.md,
      width: '100%',
      textAlign: 'center',
    },
  }));
  const { classes } = useStyles();

  return (
    <Stack justify="center" className={ classes.stack }>
      <Box className={ classes.box } mx="auto">
        <Container mb="sm" px={ 0 }>
          <Image src={ Logo } alt=""/>
        </Container>
        <div className={ classes.inline }>{ children }</div>
      </Box>
    </Stack>
  );
};

export default ErrorLayout;
