import ILayoutProps from "./ILayout";
import { Box, Container, createStyles, Image, Stack } from "@mantine/core";
import Logo from "../../assets/img/logo.png";

const AuthLayout = ({ children }: ILayoutProps) => {
  const useStyles = createStyles((theme) => ({
    stack: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      height: '100%',
    },
    box: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[0],
      padding: theme.spacing.md,
      width: '100%',
      maxWidth: 280,
      boxShadow: theme.shadows.sm,
      borderRadius: theme.radius.sm,
    },
  }));
  const { classes } = useStyles();

  return (
    <Stack justify="center" className={ classes.stack }>
      <Box className={ classes.box } mx="auto">
        <Container mb="sm" px={ 0 }>
          <Image src={ Logo } alt=""/>
        </Container>
        { children }
      </Box>
    </Stack>
  );
};

export default AuthLayout;
