import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import {ICompanyVehicleClassesParams, ICompanyVehicleClassesResponse, ICompanyVehicleClassesState} from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListCompanyVehicleClasses = createAsyncThunk(
    'listCompanyVehicleClasses/fetchListCompanyVehicleClasses',
    async ({query}: ICompanyVehicleClassesParams, {rejectWithValue}) => {
        try {
            const response = await adminApiClient.getCompanyVehicleClasses(query)
            return response.data
        } catch (error: any) {
            if (!error.response) {
                throw error
            }

            return rejectWithValue(error)
        }
    }
)

const initialState: ICompanyVehicleClassesState = {
    isLoading: false,
    response: null,
    error: null
}

const listCompanyVehicleClassesSlice = createSlice({
    name: 'groups/list',
    initialState,
    reducers: {
        resetListCompanyVehicleClasses: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(fetchListCompanyVehicleClasses.pending, state => {
            state.isLoading = true
            state.response = null
            state.error = null
        })

        builder.addCase(fetchListCompanyVehicleClasses.fulfilled, (state, action: PayloadAction<ICompanyVehicleClassesResponse>) => {
            state.isLoading = false
            state.response = action.payload
            state.error = null
        })

        builder.addCase(fetchListCompanyVehicleClasses.rejected, (state, action) => {
            state.isLoading = false
            state.response = null
            state.error = action.payload
        })
    }
})

export default listCompanyVehicleClassesSlice.reducer
export const {resetListCompanyVehicleClasses} = listCompanyVehicleClassesSlice.actions
