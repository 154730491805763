import { RoleTypes } from "./enums";
import i18n from "../utils/i18n";

export const EMAIL_REGEX_PATTERN = /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i;

export const URL_REGEX_PATTERN = /^(http|https):\/\/[^ "]+$/;

export const DEFAULT_PER_PAGE = 15;

export const DATE_FORMAT = 'M.D.YYYY';

export const DATETIME_FORMAT = 'M.D.YYYY h:mm:ss A';

export const IMAGE_ERROR_SOURCE = 'https://img.freepik.com/free-vector/blank-light-grey-blurred-background-with-radial-gradient_53562-6788.jpg?size=626&ext=jpg'

export const SELECT_PER_PAGE_OPTIONS = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
  { value: '30', label: '30' },
  { value: '35', label: '35' },
  { value: '40', label: '40' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
];

export const SELECT_ROLE_OPTIONS = [
  { value: RoleTypes.USER, label: i18n.t(`role_names.${ RoleTypes.USER }`) },
  { value: RoleTypes.ADMIN, label: i18n.t(`role_names.${ RoleTypes.ADMIN }`) },
];
