import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Select, SelectItem, Checkbox } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import * as Yup from 'yup'
import { IFormProps, IFormRef } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store'
import { fetchListUsers } from '../../../store/features/users/listUsersSlice'
import { fetchListCompanies } from '../../../store/features/companies/listCompanySlice'

const Form = forwardRef(({ id, type, initialValues, onSubmit }: IFormProps, ref: Ref<IFormRef>) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [companiesOptions, setCompaniesOptions] = useState<SelectItem[]>([])
  const [usersOptions, setUsersOptions] = useState<SelectItem[]>([])
  const { isLoading: companyIsLoading, response: companyResponse } = useAppSelector(state => state.listCompany)
  const { isLoading: userIsLoading, response: userResponse } = useAppSelector(state => state.listUsers)
  const schema = Yup.object().shape({
    company: Yup.string().nullable().required(t('validation.required')),
    user: Yup.string().nullable().required(t('validation.required')),
    is_admin: Yup.boolean().required(t('validation.required'))
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  useEffect(() => {
    if (companyResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListCompanies({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setCompaniesOptions(
        companyResponse.data.items.map((comapny: any) => ({
          value: comapny.id,
          label: `${comapny.name}`
        }))
      )
    }
  }, [companyResponse, dispatch])

  useEffect(() => {
    dispatch(fetchListUsers({ query: { page: 1, per: 1000 } }))
  }, [dispatch])

  useEffect(() => {
    if (userResponse === null) {
      ;(async () => {
        await dispatch(
          fetchListUsers({
            query: {
              page: 1,
              per: 1000
            }
          })
        )
      })()
    } else {
      setUsersOptions(
        userResponse.data.items.map((user: any) => ({
          value: user.id,
          label: `${user.name} - ${user.email}`
        }))
      )
    }
  }, [userResponse, dispatch])

  useEffect(() => {
    if (!initialized) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [initialized, form, initialValues])

  const handleReset = () => {
    form.reset()
    setInitialized(false)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => onSubmit(schema.cast(data)))}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={4}
          lg={3}
        >
          <Select
            label="Company"
            placeholder=""
            data={companiesOptions}
            disabled={companyIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('company')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={4}
          lg={3}
        >
          <Select
            label="User"
            placeholder=""
            data={usersOptions}
            disabled={userIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('user')}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col
          sm={12}
          md={4}
          lg={3}
        >
          <Checkbox
            label="Is Admin"
            {...form.getInputProps('is_admin', { type: 'checkbox' })}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
