import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IClassResponse, IClassState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchShowClass = createAsyncThunk(
  'listClasses/fetchShowClass',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getClass(id)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IClassState = {
  isLoading: false,
  response: null,
  error: null
}

const showClassSlice = createSlice({
  name: 'classes/show',
  initialState,
  reducers: {
    resetShowClass: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchShowClass.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchShowClass.fulfilled, (state, action: PayloadAction<IClassResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchShowClass.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default showClassSlice.reducer
export const { resetShowClass } = showClassSlice.actions
