import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import { IUserParams, IUserResponse, IUserState } from "../../types";

const adminApiClient = AdminApiClient.getInstance();

export const fetchUpdateUser = createAsyncThunk('listUsers/fetchUpdateUser', async (
  { id, params }: { id: string, params: IUserParams }, { rejectWithValue }
) => {
  try {
    const response = await adminApiClient.updateUser(id, params);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IUserState = {
  isLoading: false,
  response: null,
  error: null,
};

const updateUserSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateUser: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateUser.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchUpdateUser.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchUpdateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default updateUserSlice.reducer;
export const { resetUpdateUser } = updateUserSlice.actions;
