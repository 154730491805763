import { Navigate } from "react-router-dom";
import { CustomRouteRoutePropsType } from "./types";

const ProtectedRoute = (props: CustomRouteRoutePropsType) => {
  const { isAuthenticated, element } = props;

  if (isAuthenticated) {
    return element;
  }

  return <Navigate to={ { pathname: '/auth/login' } }/>;
};

export default ProtectedRoute;
