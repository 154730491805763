import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IBrandsParams, IBrandsResponse, IBrandsState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListBrands = createAsyncThunk(
  'listBrands/fetchListBrands',
  async ({ query }: IBrandsParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.getBrands(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IBrandsState = {
  isLoading: false,
  response: null,
  error: null
}

const listBrandsSlice = createSlice({
  name: 'brands/list',
  initialState,
  reducers: {
    resetListBrands: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListBrands.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListBrands.fulfilled, (state, action: PayloadAction<IBrandsResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListBrands.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listBrandsSlice.reducer
export const { resetListBrands } = listBrandsSlice.actions
