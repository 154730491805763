import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IPackagesParams, IPackagesResponse, IPackagesState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListPackages = createAsyncThunk(
  'listPackages/fetchListPackages',
  async ({ query }: IPackagesParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.packages(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IPackagesState = {
  isLoading: false,
  response: null,
  error: null
}

const listPackagesSlice = createSlice({
  name: 'packages/list',
  initialState,
  reducers: {
    resetListPackages: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListPackages.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListPackages.fulfilled, (state, action: PayloadAction<IPackagesResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListPackages.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listPackagesSlice.reducer
export const { resetListPackages } = listPackagesSlice.actions
