import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserApiClient from "../../../utils/http-clients/UserApiClient";
import { IMeResponse, IMeState } from "../../types";

const userApiClient = UserApiClient.getInstance();

export const fetchMe = createAsyncThunk('userOperations/me/fetchMe', async (params, { rejectWithValue }) => {
  try {
    const response = await userApiClient.me();
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IMeState = {
  isLoading: false,
  response: null,
  error: null,
};

const meSlice = createSlice({
  name: 'userOperations/me',
  initialState,
  reducers: {
    resetMe: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchMe.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchMe.fulfilled, (state, action: PayloadAction<IMeResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchMe.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  }
});

export default meSlice.reducer;
export const { resetMe } = meSlice.actions;
