import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Divider, Grid, Select, SelectItem, Textarea, TextInput, NumberInput} from '@mantine/core'
import {useForm, yupResolver} from '@mantine/form'
import * as Yup from 'yup'
import {IFormProps, IFormRef, RegionTypes} from '../../../types'
import {useAppDispatch, useAppSelector} from '../../../store'
import {fetchListStates, resetListStates} from '../../../store/features/shared/listStatesSlice'
import {fetchListCities} from '../../../store/features/shared/listCitiesSlice'

const Form = forwardRef(({id, type, initialValues, onSubmit}: IFormProps, ref: Ref<IFormRef>) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [stateOptions, setStateOptions] = useState<SelectItem[]>([])
  const [cityOptions, setCityOptions] = useState<SelectItem[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)
  const {isLoading: statesIsLoading, response: statesResponse} = useAppSelector(state => state.listStates)
  const {isLoading: citiesIsLoading, response: citiesResponse} = useAppSelector(state => state.listCities)

  const schema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    email: Yup.string().required(t('validation.required')).email(t('validation.invalid')),
    state: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    city: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    address: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    zip: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    phone: Yup.string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    monthly_mileage_assumption: Yup.number(),
    monthly_depreciation_assumption: Yup.number(),
    finance_length: Yup.number(),
    region: Yup.string().required(t('validation.required')),
    company: Yup.string(),
    zubie_location_name: Yup.string(),
    pickup_location_number: Yup.string()
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues
  })

  useEffect(() => {
    if (statesResponse === null) {
      ;(async () => {
        await dispatch(fetchListStates())
      })()
    } else {
      setStateOptions(
        statesResponse.data.map((state: any) => ({
          value: state.id,
          label: `${state.name} (${state.code})`
        }))
      )
    }
  }, [statesResponse, dispatch])

  useEffect(() => {
    if (citiesResponse) {
      setCityOptions(
        citiesResponse.data.map((city: any) => ({
          value: city.id,
          label: city.name
        }))
      )
      dispatch(resetListStates())
    }
  }, [citiesResponse, dispatch])

  useEffect(() => {
    if (initialValues.state) {
      ;(async () => {
        await dispatch(fetchListCities(initialValues.state))
      })()
    }
  }, [initialValues, dispatch])

  useEffect(() => {
    if (!statesIsLoading && !citiesIsLoading && !initialized) {
      form.setValues(initialValues)
      setInitialized(true)
    }
  }, [statesIsLoading, citiesIsLoading, initialized, form, initialValues])

  const handleStateChange = async (value: string) => {
    form.setFieldValue('state', value ?? '')
    form.setFieldValue('city', '')

    if (value) {
      await dispatch(fetchListCities(value))
    } else {
      setCityOptions([])
    }
  }

  const handleReset = () => {
    form.reset()
    setInitialized(false)
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset()
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message)
      })
    }
  }))

  return (
    <form
      ref={formRef}
      id={id}
      onSubmit={form.onSubmit(data => onSubmit(schema.cast(data)))}
      onReset={handleReset}
      noValidate
    >
      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('name')}
            placeholder=""
            required
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            type="email"
            label={t('email')}
            placeholder="example@mail.com"
            required
            {...form.getInputProps('email')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('state')}
            placeholder={t('select')}
            data={stateOptions}
            disabled={statesIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('state')}
            onChange={(value: string) => handleStateChange(value)}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('city')}
            placeholder={t('city')}
            data={cityOptions}
            disabled={statesIsLoading || citiesIsLoading}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('city')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Textarea
            label={t('address')}
            placeholder="123 Main St"
            autosize
            minRows={2}
            maxRows={5}
            {...form.getInputProps('address')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('zip')}
            placeholder="10011"
            {...form.getInputProps('zip')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label="Monthly Mileage Assumption"
            placeholder="1000"
            {...form.getInputProps('monthly_mileage_assumption')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label="Monthly Depreciation Assumption"
            placeholder="1000"
            {...form.getInputProps('monthly_depreciation_assumption')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <NumberInput
            label="Finance Length"
            placeholder="1000"
            {...form.getInputProps('finance_length')}
          />
        </Grid.Col>
      </Grid>

      <Divider
        variant="dashed"
        size="xs"
        mt="lg"
        mb="md"
      />

      <Grid>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <Select
            label={t('region')}
            placeholder={t('region')}
            data={[
              {value: RegionTypes.NA.toString(), label: RegionTypes.NA.toString()},
              {value: RegionTypes.SE.toString(), label: RegionTypes.SE.toString()},
              {value: RegionTypes.NE.toString(), label: RegionTypes.NE.toString()},
              {value: RegionTypes.MW.toString(), label: RegionTypes.MW.toString()},
              {value: RegionTypes.SW.toString(), label: RegionTypes.SW.toString()},
              {value: RegionTypes.WC.toString(), label: RegionTypes.WC.toString()},
            ]}
            searchable
            nothingFound={t('errors.no_options')}
            clearable
            {...form.getInputProps('region')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('zubie_location_name')}
            placeholder={t('zubie_location_name')}
            {...form.getInputProps('zubie_location_name')}
          />
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
        >
          <TextInput
            label={t('pickup_location_number')}
            placeholder={t('pickup_location_number')}
            {...form.getInputProps('pickup_location_number')}
          />
        </Grid.Col>
      </Grid>
    </form>
  )
})

export default Form
