import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IResendState, IResponse } from '../../types'

const sharedApiClient = AdminApiClient.getInstance()

export const fetchResendInvoice = createAsyncThunk(
  'listInvoices/fetchResendInvoice',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await sharedApiClient.resendInvoice(id)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IResendState = {
  isLoading: false,
  response: null,
  error: null
}

const resendInvoiceSlice = createSlice({
  name: 'invoices/resend',
  initialState,
  reducers: {
    resetResendInvoice: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchResendInvoice.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchResendInvoice.fulfilled, (state, action: PayloadAction<IResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchResendInvoice.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default resendInvoiceSlice.reducer
export const { resetResendInvoice } = resendInvoiceSlice.actions
