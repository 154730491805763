import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IInvoicesParams, IInvoicesResponse, IInvoicesState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchListInvoices = createAsyncThunk(
  'listInvoices/fetchListInvoices',
  async ({ query }: IInvoicesParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.invoices(query)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IInvoicesState = {
  isLoading: false,
  response: null,
  error: null
}

const listInvoicesSlice = createSlice({
  name: 'invoices/list',
  initialState,
  reducers: {
    resetListInvoices: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchListInvoices.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchListInvoices.fulfilled, (state, action: PayloadAction<IInvoicesResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchListInvoices.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default listInvoicesSlice.reducer
export const { resetListInvoices } = listInvoicesSlice.actions
