import React, { useState, useLayoutEffect, useEffect } from "react";
import { Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { RouteItemType } from "../config/routes";
import { useAppDispatch, useAppSelector } from "../store";
import { history } from "../utils/history";
import { routes } from "../config";
import { fetchMe, resetMe } from "../store/features/userOperations/meSlice";
import { RoleTypes } from "../types";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { logout } from "../store/features/auth/loginSlice";
import AppStorage from "../utils/storage";

/**
 * Converts the router tree to a flat list.
 * @param routes
 */
export const getFlatRoutes = (routes: Array<RouteItemType>) => {
  let _routes: Array<RouteItemType> = [];

  const worker = (items: Array<RouteItemType>, path?: string) => {
    if (items.length > 0) {
      items.forEach(item => {
        const nestedPath = ((path ? path : '') + item.path).replace(/\/\/+/g, '/');

        if (item.component !== undefined) {
          _routes.push({
            path: nestedPath,
            component: item.component,
            authRequired: item.authRequired,
            breadcrumb: item.breadcrumb,
          });
        }

        if (item.routes !== undefined && item.routes.length > 0) {
          worker(item.routes, nestedPath);
        }
      });
    }
  };

  worker(routes);

  return _routes;
};

/**
 * AppRouter component.
 * @constructor
 */
export const AppRouter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({ action: history.action, location: history.location });
  const [routeItems, setRouteItems] = useState<Array<RouteItemType>>([]);
  const me = useAppSelector(state => state.me);

  useLayoutEffect(() => history.listen(setState), []);

  useEffect(() => {
    if (me.response === null && AppStorage.getAccessToken() !== null) {
      dispatch(fetchMe());
    }
  }, [me.response, dispatch]);

  useEffect(() => {
    if (me.response !== null) {
      if (me.response.data.roles.indexOf(RoleTypes.ADMIN) === -1) {
        dispatch(logout());
        dispatch(resetMe());
        showNotification({ color: 'red', title: t('error'), message: t('errors.auth_forbidden') });
        return;
      }
    }
  }, [me.response, dispatch, t]);

  useEffect(() => {
    setRouteItems(getFlatRoutes(routes));
  }, [me.response]);

  return (
    <>
      { (routeItems.length > 0 && !me.isLoading) &&
        <Router navigator={ history } location={ state.location }>
          <Routes>
            { routeItems.map((route, key) => {
              if (route.component === undefined) {
                return null;
              }

              if (route.authRequired !== undefined) {
                if (route.authRequired) {
                  return <Route path={ route.path }
                                element={ <ProtectedRoute isAuthenticated={ me.response !== null }
                                                          element={ <route.component/> }/> }
                                key={ key }/>;
                } else {
                  return <Route path={ route.path }
                                element={ <PublicRoute isAuthenticated={ me.response !== null }
                                                       element={ <route.component/> }/> }
                                key={ key }/>;
                }
              }

              return <Route path={ route.path } element={ <route.component/> } key={ key }/>;
            }) }
          </Routes>
        </Router>
      }
    </>
  );
}

export default AppRouter;
