import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SharedApiClient from "../../../utils/http-clients/SharedApiClient";
import { IStatesResponse, IStatesState } from "../../types";

const sharedApiClient = SharedApiClient.getInstance();

export const fetchListStates = createAsyncThunk('listStates/fetchListStates', async (
  _, { rejectWithValue }
) => {
  try {
    const response = await sharedApiClient.states();
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IStatesState = {
  isLoading: false,
  response: null,
  error: null,
};

const listStatesSlice = createSlice({
  name: 'states/list',
  initialState,
  reducers: {
    resetListStates: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchListStates.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchListStates.fulfilled, (state, action: PayloadAction<IStatesResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchListStates.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default listStatesSlice.reducer;
export const { resetListStates } = listStatesSlice.actions;
