import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IClassParams, IClassResponse, IClassState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchCreateClass = createAsyncThunk(
  'listClasses/fetchCreateClass',
  async (params: IClassParams, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.createClass(params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IClassState = {
  isLoading: false,
  response: null,
  error: null
}

const createClassSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetCreateClass: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchCreateClass.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchCreateClass.fulfilled, (state, action: PayloadAction<IClassResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchCreateClass.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default createClassSlice.reducer
export const { resetCreateClass } = createClassSlice.actions
