import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Divider,
  Grid,
  MultiSelect, NumberInput,
  PasswordInput,
  Select,
  SelectItem,
  Textarea,
  TextInput
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { FormTypes, IFormProps, IFormRef, SELECT_ROLE_OPTIONS } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchListStates, resetListStates } from "../../../store/features/shared/listStatesSlice";
import { fetchListCities } from "../../../store/features/shared/listCitiesSlice";

const Form = forwardRef(({ id, type, initialValues, onSubmit }: IFormProps, ref: Ref<IFormRef>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [stateOptions, setStateOptions] = useState<SelectItem[]>([]);
  const [cityOptions, setCityOptions] = useState<SelectItem[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);
  const {
    isLoading: statesIsLoading,
    response: statesResponse
  } = useAppSelector(state => state.listStates);
  const {
    isLoading: citiesIsLoading,
    response: citiesResponse
  } = useAppSelector(state => state.listCities);

  const schema = Yup.object().shape({
    roles: Yup.array().min(1, t('validation.min_select', { length: 1 })),
    name: Yup.string().required(t('validation.required')),
    last_name: Yup.string().required(t('validation.required')),
    email: Yup.string().required(t('validation.required')).email(t('validation.invalid')),
    password: Yup.string().nullable()
      .when({
        is: (value: string) => type === FormTypes.CREATE || value.length > 0,
        then: Yup.string().nullable()
          .required(t('validation.required')).min(4, t('validation.min', { length: 4 })),
      }),
    password_confirmation: Yup.string().nullable()
      .when({
        is: () => type === FormTypes.CREATE,
        then: Yup.string().nullable().required(t('validation.required'))
      }).oneOf([Yup.ref('password'), null], t('validation.do_not_much')),
    state: Yup.string().nullable().transform(value => value === '' ? null : value),
    city: Yup.string().nullable().transform(value => value === '' ? null : value),
    address: Yup.string().nullable().transform(value => value === '' ? null : value),
    zip: Yup.string().nullable().transform(value => value === '' ? null : value),
    is_activated: Yup.boolean(),
    is_future_value: Yup.boolean(),
    is_projected_rev_loss: Yup.boolean(),
    is_history_reports: Yup.boolean(),
    is_weekly_reports: Yup.boolean(),
  });

  const form = useForm({
    schema: yupResolver(schema),
    initialValues,
  });

  useEffect(() => {
    if (statesResponse === null) {
      (async () => {
        await dispatch(fetchListStates());
      })();
    } else {
      setStateOptions(statesResponse.data.map((state: any) => ({
        value: state.id,
        label: `${ state.name } (${ state.code })`
      })));
    }
  }, [statesResponse, dispatch]);

  useEffect(() => {
    if (citiesResponse) {
      setCityOptions(citiesResponse.data.map((city: any) => ({
        value: city.id,
        label: city.name
      })));
      dispatch(resetListStates());
    }
  }, [citiesResponse, dispatch]);

  useEffect(() => {
    if (initialValues.state) {
      (async () => {
        await dispatch(fetchListCities(initialValues.state));
      })();
    }
  }, [initialValues, dispatch]);

  useEffect(() => {
    if (!statesIsLoading && !citiesIsLoading && !initialized) {
      form.setValues(initialValues);
      setInitialized(true);
    }
  }, [statesIsLoading, citiesIsLoading, initialized, form, initialValues]);

  const handleStateChange = async (value: string) => {
    form.setFieldValue('state', value ?? '');
    form.setFieldValue('city', '');

    if (value) {
      await dispatch(fetchListCities(value));
    } else {
      setCityOptions([]);
    }
  };

  const handleReset = () => {
    form.reset();
    setInitialized(false);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset();
    },
    setErrors: errors => {
      errors.forEach(error => {
        form.setFieldError(error.key, error.message);
      });
    }
  }));

  return (
    <form ref={ formRef }
          id={ id }
          onSubmit={ form.onSubmit(data => onSubmit(schema.cast(data))) }
          onReset={ handleReset }
          noValidate>

      <Grid>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <MultiSelect
            data={ SELECT_ROLE_OPTIONS }
            label={ t('roles') }
            placeholder={ t('select') }
            searchable
            nothingFound={ t('errors.no_options') }
            clearable
            autoFocus
            required
            { ...form.getInputProps('roles') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <TextInput
            label={ t('name') }
            placeholder="John"
            required
            { ...form.getInputProps('name') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <TextInput
            label={ t('lastname') }
            placeholder="Doe"
            required
            { ...form.getInputProps('last_name') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <TextInput
            type="email"
            label={ t('email') }
            placeholder="example@mail.com"
            required
            { ...form.getInputProps('email') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <PasswordInput
            label={ t('password') }
            placeholder="p@ssw0rd"
            required={ type === FormTypes.CREATE }
            { ...form.getInputProps('password') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <PasswordInput
            label={ t('password_confirmation') }
            placeholder="p@ssw0rd"
            required={ type === FormTypes.CREATE }
            { ...form.getInputProps('password_confirmation') } />
        </Grid.Col>
      </Grid>

      <Divider variant="dashed" size="xs" mt="lg" mb="md"/>

      <Grid>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Select
            label={ t('state') }
            placeholder={ t('select') }
            data={ stateOptions }
            disabled={ statesIsLoading }
            searchable
            nothingFound={ t('errors.no_options') }
            clearable
            { ...form.getInputProps('state') } onChange={ (value: string) => handleStateChange(value) }/>
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Select
            label={ t('city') }
            placeholder={ t('city') }
            data={ cityOptions }
            disabled={ statesIsLoading || citiesIsLoading }
            searchable
            nothingFound={ t('errors.no_options') }
            clearable
            { ...form.getInputProps('city') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Textarea
            label={ t('address') }
            placeholder="123 Main St"
            autosize
            minRows={ 2 }
            maxRows={ 5 }
            { ...form.getInputProps('address') } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <TextInput
            label={ t('zip') }
            placeholder="10011"
            { ...form.getInputProps('zip') } />
        </Grid.Col>
      </Grid>

      <Divider variant="dashed" size="xs" mt="lg" mb="md"/>

      <Grid>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Checkbox label={ t('is_activated') }
                    { ...form.getInputProps('is_activated', { type: 'checkbox' }) } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Checkbox label={ t('future_value') }
                    { ...form.getInputProps('is_future_value', { type: 'checkbox' }) } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Checkbox
            label={ t('projected_rev_loss') }
            { ...form.getInputProps('is_projected_rev_loss', { type: 'checkbox' }) } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Checkbox
            label={ t('history_reports') }
            { ...form.getInputProps('is_history_reports', { type: 'checkbox' }) } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Checkbox
            label={ t('weekly_reports') }
            { ...form.getInputProps('is_weekly_reports', { type: 'checkbox' }) } />
        </Grid.Col>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <Checkbox
            label={ t('class_performances') }
            { ...form.getInputProps('is_class_performances', { type: 'checkbox' }) } />
        </Grid.Col>
      </Grid>

      <Divider variant="dashed" size="xs" mt="lg" mb="md"/>

      <Grid>
        <Grid.Col sm={ 12 } md={ 6 } lg={ 4 } xl={ 3 }>
          <NumberInput
            label={ t('price_alert_permit_count') }
            min={ 0 }
            { ...form.getInputProps('price_alert_permit_count') } />
        </Grid.Col>
      </Grid>

    </form>
  );
});

export default Form;
