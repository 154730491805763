import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdminApiClient from "../../../utils/http-clients/AdminApiClient";
import { IUserParams, IUserResponse, IUserState } from "../../types";

const adminApiClient = AdminApiClient.getInstance();

export const fetchCreateUser = createAsyncThunk('listUsers/fetchCreateUser', async (
  params: IUserParams, { rejectWithValue }
) => {
  try {
    const response = await adminApiClient.createUser(params);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error
    }

    return rejectWithValue(error);
  }
});

const initialState: IUserState = {
  isLoading: false,
  response: null,
  error: null,
};

const createUserSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetCreateUser: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchCreateUser.pending, state => {
      state.isLoading = true;
      state.response = null;
      state.error = null;
    });

    builder.addCase(fetchCreateUser.fulfilled, (state, action: PayloadAction<IUserResponse>) => {
      state.isLoading = false;
      state.response = action.payload;
      state.error = null;
    });

    builder.addCase(fetchCreateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.response = null;
      state.error = action.payload;
    });
  },
});

export default createUserSlice.reducer;
export const { resetCreateUser } = createUserSlice.actions;
