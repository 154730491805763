import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AdminApiClient from '../../../utils/http-clients/AdminApiClient'
import { IGroupParams, IGroupResponse, IGroupState } from '../../types'

const adminApiClient = AdminApiClient.getInstance()

export const fetchUpdateGroup = createAsyncThunk(
  'listGroups/fetchUpdateGroup',
  async ({ id, params }: { id: string; params: IGroupParams }, { rejectWithValue }) => {
    try {
      const response = await adminApiClient.updateGroup(id, params)
      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error
      }

      return rejectWithValue(error)
    }
  }
)

const initialState: IGroupState = {
  isLoading: false,
  response: null,
  error: null
}

const updateGroupSlice = createSlice({
  name: 'states/show',
  initialState,
  reducers: {
    resetUpdateGroup: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchUpdateGroup.pending, state => {
      state.isLoading = true
      state.response = null
      state.error = null
    })

    builder.addCase(fetchUpdateGroup.fulfilled, (state, action: PayloadAction<IGroupResponse>) => {
      state.isLoading = false
      state.response = action.payload
      state.error = null
    })

    builder.addCase(fetchUpdateGroup.rejected, (state, action) => {
      state.isLoading = false
      state.response = null
      state.error = action.payload
    })
  }
})

export default updateGroupSlice.reducer
export const { resetUpdateGroup } = updateGroupSlice.actions
