import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@mantine/core";
import { ArrowBack as ArrowBackIcon } from "tabler-icons-react";

type PropsType = {
  to: string;
}

const BackListButton = ({ to }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Button component={ NavLink } to={ to } leftIcon={ <ArrowBackIcon size={ 18 }/> } variant="outline">
      { t('content_actions.list') }
    </Button>
  );
}

export default BackListButton;
